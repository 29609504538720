import styled from "styled-components";

export const Image = styled.div`
	display: inline-block;
	height: 3rem;
	width: 3rem;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 50%;
	}
`;
