import React from "react";
import { CloudinaryImage } from "@website-builder/ui/shared/elements";
import { BannerWrapper, BlogTitle } from "./styles";
import transform from "./transform";

const BlogImageBanner = ({
	bannerConfig: { imageUrl, altTag },
	context,
	mobileBannerConfig,
}) => {
	const mobileImageUrl = mobileBannerConfig?.imageUrl || "";
	const mobileAltTag = mobileBannerConfig?.altTag || "";
	return (
		<>
			<BannerWrapper>
				{imageUrl || altTag ? (
					<CloudinaryImage
						className="banner-image"
						url={imageUrl}
						alt={altTag}
						mobileImageUrl={mobileImageUrl}
						mobileAltTag={mobileAltTag}
					/>
				) : null}
			</BannerWrapper>
			<BlogTitle>{context.title}</BlogTitle>
		</>
	);
};

export const blogImageBannerTransform = transform;
export default BlogImageBanner;
