import styled from "styled-components";

export const CourseMenuWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 40px;
	${({ isMobile }) =>
		!isMobile
			? `padding: 40px;
    padding-left: 24px;
    padding-top: 32px;`
			: ""}
`;
export const LeftSection = styled.div`
	min-width: 282px;
	display: ${({ show }) => (show ? "block" : "none")};
`;
export const CourseMenuItemWrapper = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
	width: fit-content;
`;
export const CourseMenuItem = styled.button`
	background: transparent;
	box-shadow: none;
	width: 100%;
	border: none;
	text-align: left;
	// removes default button styling

	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	padding: ${({ isMobile }) => (!isMobile ? "8px 16px" : "8px")};
	margin-bottom: 8px;

	&:hover,
	&:focus {
		& p {
			font-weight: var(--fw-haffer-xh-2);
		}
		cursor: pointer;
	}

	&.active {
		background-color: ${(props) => props.bgColor};
		border-radius: 28px;
		margin-left: 0px;

		& p {
			color: ${(props) => props.textColor};
			font-weight: var(--fw-haffer-xh-2);
		}
	}
`;
export const CourseMenuFooter = styled.div`
	margin-top: 16px;
	margin-left: 16px;
	display: flex;
	flex-direction: column;
	& button {
		margin-top: 2rem;
	}

	& .footerLinkMobile > p {
		margin-bottom: 1.5rem;
	}
`;

export const AnimatedDiv = styled.div`
	display: flex;
	flex-direction: row;
	gap: 40px;

	@keyframes inAnimationDiv {
		0% {
			width: 0;
		}
		100% {
			width: 340px;
		}
	}

	animation: ${({ selectedItemIndex }) =>
		selectedItemIndex >= 0 ? "inAnimationDiv 500ms ease-out" : "none"};
`;

export const RightSection = styled.div`
	max-width: 314px;
`;

export const CourseActions = styled.div`
	width: 300px;
	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		80% {
			opacity: 0;
		}
		90% {
			opacity: 0.5;
		}
		100% {
			opacity: 1;
		}
	}
	animation: fadeIn 600ms ease-out;
`;
export const CourseActionsMobile = styled.div`
	width: 100%;
	display: ${({ show }) => (show ? "block" : "none")};
`;

export const CourseActionsHeading = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 24px 0;
	gap: 8px;
`;

export const CourseActionItem = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: ${({ cIndex }) => (cIndex ? "24px" : "0")};
	margin-bottom: 24px;
`;

export const Courses = styled.div`
	display: none;
	border-spacing: 0;
	&.show {
		display: block;
	}
`;
