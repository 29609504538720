import styled from "styled-components";
import { FONT_WT_LIGHT } from "@website-builder/utilities-index";

export const RichText = styled.div`
	p {
		min-height: 1px;
		code {
			background-color: var(--primaryLight);
			border: 1px solid var(--primary);
			border-radius: 3px;
			padding: 0px 2px;
		}
	}
	ul {
		padding-left: 18px;
	}
	blockquote {
		background-color: var(--primaryLight);
		border-left: 0.25em solid var(--primary);
		padding: 10px 0em 10px 1em;
		margin: 40px 0px;
		font-size: 20px;
		p {
			margin: 0;
		}
	}
	pre {
		margin: 40px 0px !important;
	}
	a,
	a:visited,
	a:active,
	a:hover {
		color: inherit;
	}
`;
