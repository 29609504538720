export const validateEmail = (value) => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	if (emailRegex.test(value)) {
		return true;
	}
	if (value && value.length) {
		return "Please enter a valid email address";
	} else {
		return "This field is required";
	}
};
