export const HS_API = {
	GET_FORM_FIELDS: `hubspot/get-form-fields`,
	POST_FORM_DATA: `hubspot/submit-form/`,
	GET_FORM: `hubspot/get-form`,
	LEAD_API: `interested/hubspot-form/application/`,
	APP_STARTED_API: `learning-paths/interested/`,
};

export const ZAPIER_WEB_HOOKS = {
	SLACK: "https://hooks.zapier.com/hooks/catch/1410935/3ht68wn/",
	EMAIL: "https://hooks.zapier.com/hooks/catch/1410935/3htm1lg/",
};

export const DESTINATIONS = {
	HUBSPOT: "hubspot",
	ACTIVE_CAMPAIGN: "active-campaign",
};

export const DEFAULT_ACTIVE_CAMPAIGN_FIELDS = {
	email: "email",
	firstname: "firstName",
	lastname: "lastName",
	phone: "phone",
};

export const ACTIVE_CAMPAIGN_FIELD_VALUES = {
	preferred_course: "13",
	gender_self_described_: "16",
	terms_and_conditions: "17",
	zip: "11",
	lead_type: "125",
};

export const AC_LEAD_TYPE_VALUE = {
	STAGE_FORM: "application-stage-form",
};

export const FieldProps = {
	name: "",
	// String; The name of the field. This needs to match the internal name of a contact property.
	label: "",
	// String; The label that will appear for the field.
	type: "",
	// String, one of string, number, date, datetime, or enumeration
	// This needs to match the 'type' field of the corresponding contact property.
	fieldType: "",
	// String, one of textarea, text, date, file, number, select, radio, checkbox, or booleancheckbox
	// The type of field that will be used when the form is embedded.
	description: "",
	// String; The help text that displays below the label.
	groupName: "",
	// DEPRECATED - this field is not used.
	displayOrder: -1,
	// Integer; The order to display the fields in.
	//If the values are negative, the fields appear in the order they appear in the 'fields' list
	required: false,
	// Boolean; Required fields must be filled out to submit the form.
	selectedOptions: [],
	// For enumerated fields, this will be a list of Strings representing the options that will be selected by default
	options: [],
	// For enumerated fields, this will be a list of Strings representing the available options for the field
	// Will be empty for non-enumerated fields.
	validation: {
		// A set of options controlling the validation for the field
		// NOTE: These options should NOT be modified through the API. Any validation should be set up in the form settings in HubSpot.
		name: "",
		message: "",
		data: "",
		useDefaultBlockList: false,
		blockedEmailAddresses: [],
	},
	enabled: true,
	// DEPRECATED - this field is not used.
	hidden: false,
	// Boolean; When set to true, the field will be rendered as a hidden field.
	defaultValue: "",
	// String; The default value of the field
	isSmartField: false,
	// Boolean; Whether or not the field is a smart field.
	// Smart fields are hidden if the visitor is a known contact that already has a value for the field.)
	unselectedLabel: "",
	// DEPRECATED - this field is not used.
	placeholder: "",
	// String; The placeholder text for the field, which will display
	dependentFieldFilters: [],
	// A list of filters that will be used to display dependent fields.
};

// These are field-type values coming from Hubspot Forms APIs
export const FIELD_TYPES = {
	TEXT: "text",
	TEXTAREA: "textarea",
	SELECT: "select",
	NUMBER: "number",
	PHONE: "phonenumber",
	MULTI_CHECKBOX: "checkbox", // Multiple Checkbox options (like "choose the options that apply to you")
	SINGLE_CHECKBOX: "booleancheckbox", // Single Checkbox (like a consent checkbox)
	RADIO: "radio",
};

export const FILTER_TYPES = {
	SET_ANY: "SET_ANY",
	SET_NOT_ANY: "SET_NOT_ANY",
	IS_NOT_EMPTY: "IS_NOT_EMPTY",
};

export const HS_APP_LEAD_TYPE = "AP";
export const OBJECT_TYPE_ID = "0-1";

export const STAGED_FORM_BG_TYPES = {
	PALE_GREEN: "pale_green",
	LIME_GREEN: "lime_green",
	FOREST_GREEN: "forest_green",
	WHITE: "white",
};

export const STAGED_FORM_COLORS = {
	BG: {
		DEFAULT: "var(--primary-green-400)",
		PALE_GREEN: "var(--primary-green-50)",
		LIME_GREEN: "var(--primary-green-400)",
		FOREST_GREEN: "var(--primary-forest-600)",
		WHITE: "var(--primary-neutral-white)",
	},
	PROGRESS_BAR: {
		DEFAULT: "var(--primary-green-50)",
		BG_LIGHT: "var(--primary-green-400)",
		BG_ON_WHITE: "var(--secondary-gray-medium)",
	},
	TEXT: {
		DEFAULT: "var(--primary-neutral-nightshade-800)",
		LIGHT: "var(--primary-neutral-white)",
	},
	LINK: {
		ON_LIGHT: "var(--secondary-purple-300)",
		ON_DARK: "var(--secondary-purple-100)",
	},
};

export const HUBSPOT_DATA_EVENTS = {
	FORM_SUBMIT: "hubspot_form_submit", // On-Form Submit for B2C
	LEAD_FORM_SUBMIT: "lead_form_submit", // Application Form Submit for B2C and B2U
	FORM_SUBMIT_FAIL: "hubspot_form_submit_fail", // On-Form Submit Failure for B2C
	LEAD_FORM_SUBMIT_FAIL: "lead_form_submit_fail", // Application Form Submit Failure for B2C and B2U
	SYLLABUS_DOWNLOAD: "syllabus_download", // Syllabus Download for B2C and B2U
	SYLLABUS_DOWNLOAD_FAIL: "syllabus_download_fail", // Syllabus Download Failure for B2C and B2U
};
