import { MOBILE_L_BP } from "@website-builder/utilities-index";
import styled from "styled-components";

export const BlogListWrapper = styled.div`
	margin-bottom: 48px;
	.latest-posts-cards-wrapper,
	.featured-posts-cards-wrapper {
		display: grid;
		grid-template-columns: repeat(auto-fill, 300px);
		grid-gap: 48px 24px;
		justify-content: space-between;
		//Below media queries are tailored to make the spacing between the cards look good- so we haven't used constants here
		@media only screen and (max-width: 1470px) {
			grid-template-columns: repeat(auto-fill, 250px);
		}
		@media only screen and (max-width: 1270px) {
			grid-template-columns: repeat(auto-fill, 350px);
		}
		@media only screen and (max-width: 1170px) {
			grid-template-columns: repeat(auto-fill, 300px);
		}
		@media only screen and (max-width: 1050px) {
			grid-template-columns: repeat(auto-fill, 250px);
		}
		@media only screen and (max-width: 850px) {
			grid-template-columns: repeat(auto-fill, 300px);
		}
		@media only screen and (max-width: ${MOBILE_L_BP}px) {
			justify-content: center;
			grid-template-columns: 1fr;
		}
	}
	.latest-posts-headline,
	.featured-posts-headline {
		margin-bottom: 24px;
	}
	.show-more-blog-posts-link {
		margin-top: 48px;
	}
`;
