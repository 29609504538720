import React from "react";
import PropTypes from "prop-types";
import { scrollToElement } from "@website-builder/utilities-index";
import { Link } from "gatsby";
import { AElement } from "../";

const CtaLink = (props) => {
	const { type, value, target, className, children, convertToButton } = props;

	/**
	 * This function will add trailing slashes for the url which doesn't have
	 * query params(eg:http://domain.com?productid=xyz)or
	 *  ending with filename(eg:https://domain.com/springboard-images/Jo_Liu-modified.png)
	 * @param {String} url
	 * @returns String
	 */
	const addTrailingSlash = (url) => {
		const regex = /^[^?]*\/(?!\/)[^?.]*[^/?.]$/g;
		if (regex.test(url)) {
			return `${url}/`;
		}
		return url;
	};
	const url = addTrailingSlash(value);
	if (type === "hash") {
		if (convertToButton) {
			return (
				<button className={className} onClick={() => scrollToElement(value)}>
					{children}
				</button>
			);
		}
		return (
			<AElement className={className} onClick={() => scrollToElement(value)}>
				{children}
			</AElement>
		);
	}

	if (type === "external" || type === "internal") {
		if (convertToButton) {
			return (
				<button
					className={className}
					onClick={() => {
						window.location = url;
					}}
				>
					{children}
				</button>
			);
		}
		return (
			<AElement className={className} href={url} target={target}>
				{children}
			</AElement>
		);
	}
};

CtaLink.propTypes = {
	type: PropTypes.oneOf(["hash", "external", "internal"]),
	value: PropTypes.string,
	className: PropTypes.string,
	pathName: PropTypes.string,
	target: PropTypes.oneOf(["_blank", "_self"]),
	convertToButton: PropTypes.bool,
};

CtaLink.defaultProps = {
	className: "",
	type: "hash",
	value: "",
	pathName: "",
	Link: Link,
	target: "_blank",
	convertToButton: false,
};

export default CtaLink;
