import React from "react";
import BreadCrumbs from "../../../../Elements/BreadCrumbs";
import transform from "./transform";

const BlogBreadCrumbs = ({ breadCrumbs = [] }) => {
	return <BreadCrumbs list={breadCrumbs}></BreadCrumbs>;
};

export const blogBreadCrumbsTransform = transform;
export default BlogBreadCrumbs;
