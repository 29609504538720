import styled from "styled-components";
import { DESKTOP_SM_BP, TABLET_BP } from "@website-builder/utilities-index";

export const Wrapper = styled.section`
	width: ${({ desktop }) => `${desktop}%`};
	@media only screen and (max-width: ${DESKTOP_SM_BP - 1}px) {
		width: ${({ tablet }) => `${tablet}%`};
	}
	@media only screen and (max-width: ${TABLET_BP - 1}px) {
		width: 100%;
	}
	> * {
		margin: 10px;
	}
`;
