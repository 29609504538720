import styled from "styled-components";

import {
	TABLET_BP,
	MOBILE_L_BP,
	FONT_WT_HEAVY,
	FONT_WT_REGULAR,
	FONT_WT_LIGHT,
	COLOR_WHITE,
	COLOR_NOBEL_GREY,
	COLOR_LIGHTEST_GREY,
	FONT_SIZE_22,
	FONT_SIZE_26,
	FONT_SIZE_18,
	FONT_SIZE_16,
} from "@website-builder/utilities-index";

export const Header = styled.h2`
	font-weight: ${FONT_WT_HEAVY};
	font-size: ${({ headerSize }) =>
		headerSize?.value && headerSize.value === "header26" //For Backward Compatibility
			? FONT_SIZE_26
			: !headerSize.value
				? headerSize + "px"
				: FONT_SIZE_22};
	text-align: ${(props) => (props.align === "left" ? "left" : "center")};
	margin-bottom: ${(props) => (props.align === "left" ? "10px" : "25px")};
	@media only screen and (max-width: ${TABLET_BP}px) {
		font-size: 22px;
		line-height: 28px;
	}
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		font-size: ${FONT_SIZE_18};
		line-height: 23px;
		padding-left: 15px;
	}
`;

export const DropdownItem = styled.div`
	position: relative;
	font-size: ${FONT_SIZE_18};
	text-align: left;
	padding: 15px;
	min-width: 85px;
	height: 100%;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: ${(props) =>
		props.activeIem ? "none" : `1px solid ${COLOR_NOBEL_GREY}`};
	${(props) =>
		props.activeIem && (props.activeStateColor || props.inactiveStateColor)
			? `background-color: ${props.activeStateColor};`
			: `background-color: ${props.inactiveStateColor};`}
	${(props) =>
		props.activeIem &&
		props.activeStateColor &&
		props.activeStateColor !== "#ffffff" &&
		`color: ${COLOR_WHITE};`}
  font-weight: ${FONT_WT_HEAVY};
	.submenuIcon {
		width: 16px;
		height: 16px;
	}
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		font-size: ${FONT_SIZE_16};
	}
`;

export const AccordionWrapper = styled.div`
	padding: 0 35px 15px 35px;
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		padding: 0;
	}
`;

export const DropdownContent = styled.div`
	display: ${(props) => (props.showDropdown ? "flex" : "none")};
	padding: 5px 30px 5px 15px;
	flex-direction: column;
	z-index: 10;
	animation-timing-function: ease-in-out;
	font-weight: ${FONT_WT_LIGHT};
	background-color: ${COLOR_WHITE};
	a,
	a:visited,
	a:active,
	a:hover {
		font-weight: ${FONT_WT_LIGHT};
		color: inherit;
	}
`;

export const SubHeading = styled.p`
	margin: 0;
	margin-bottom: 25px;
	text-align: ${(props) => (props.align === "left" ? "left" : "center")};
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		padding-left: ${(props) => (props.align === "left" ? "15px" : "0px")};
	}
`;
