import styled from "styled-components";

export const BannerWrapper = styled.div`
	margin: 15px 0px 0px;
	.banner-image {
		width: 100%;
	}
`;

export const BlogTitle = styled.h1`
	margin: 28px 0px 0px;
	font-size: 2em;
`;
