import {
	getLogoOrImageConfig,
	transformLinkWithLabel,
} from "@website-builder/utilities-index";
import customFooterTransform from "./CustomFooter/transform";
import { COLOR_WHITE } from "@website-builder/utilities-index";

export const transform = (footer) => {
	const {
		logoConfig = {},
		hasNavigations = { value: false },
		navigationColumns = [],
		backgroundColor = "footer",
		fontColor = "",
		address = { html: "" },
		copyrightYear = "",
		copyrightText = "",
		hasCustomFooter = false,
		customFooter = [],
		hasDefaultFooter = { value: true },
		disclaimerSection = "",
		disclaimerColor = "",
	} = footer;

	return {
		logoConfig: getLogoOrImageConfig(logoConfig),
		hasNavigations: hasNavigations.value,
		navigationColumns: navigationColumns.map(({ navigationBlocks = [] }) => {
			return {
				navigationBlocks: navigationBlocks.map(
					({ title = "", link = {}, navigations = [] }) => {
						return {
							title,
							link: transformLinkWithLabel(link),
							navigations: navigations.map(({ name = "", link = {} }) => {
								return {
									name,
									link: transformLinkWithLabel(link),
								};
							}),
						};
					},
				),
			};
		}),
		address: address.html,
		copyrightYear: copyrightYear,
		copyrightText: copyrightText,
		backgroundColor,
		fontColor: fontColor.color || COLOR_WHITE,
		hasCustomFooter,
		customFooter: customFooterTransform(
			customFooter.length ? customFooter[0] : {},
		),
		hasDefaultFooter: hasDefaultFooter.value,
		disclaimerSection: disclaimerSection,
		disclaimerColor: disclaimerColor,
	};
};
