import {
	addToDataLayer,
	getGclid,
} from "@website-builder/utilities/utils/utils.js";
import { GetReferrer } from "@website-builder/utilities/utils/GetReferrer.js";
import {
	GTM_EVENT_TIMEOUT,
	WAIT_TIME_FOR_ANALYTICS,
} from "@website-builder/utilities/constants/click-events.js";
import { GetReferrerUtmParams } from "@website-builder/utilities/utils/GetReferrerUtmParams.js";
import { getVwoCampaignId } from "@website-builder/utilities/utils/VWO.js";
import { getUniqueUserId } from "@website-builder/utilities/utils/uniqueId.js";
import { getSegmentAnonymousId } from "@website-builder/utilities/utils/SegmentEvents.js";
import {
	formatColor,
	handleCTAClick,
	openTypeFormURLWithParameters,
} from "@website-builder/utilities/utils/DesignUtils.js";
import {
	ACTIVE_CAMPAIGN_FIELD_VALUES,
	FIELD_TYPES,
	HS_APP_LEAD_TYPE,
	OBJECT_TYPE_ID,
	STAGED_FORM_BG_TYPES,
	STAGED_FORM_COLORS,
} from "./constants";
export const transformHubspotFormData = (form) => {
	const updatedFormData = [];
	const dependentFields = [];
	const formData = form?.formFieldGroups
		?.map(({ fields }) => {
			if (fields?.length === 2) {
				return fields.map((field) => ({ ...field, isHalfField: true }));
			}
			return fields;
		})
		.flat(1);
	formData?.forEach((field, parentIndex) => {
		updatedFormData.push(field);
		if (field.dependentFieldFilters.length) {
			field.dependentFieldFilters?.forEach((depField, index) => {
				const fieldDetails = {
					name: depField?.dependentFormField?.name,
					parentName: field?.name,
					index: parentIndex + (index + 1),
					isDepHidden: true,
					operator: depField?.filters?.[0]?.operator,
					strValues: depField?.filters?.[0]?.strValues,
				};
				dependentFields.push(fieldDetails);
				updatedFormData.push({
					...depField?.dependentFormField,
					isDepHidden: true,
					isHalfField: field?.isHalfField,
				});
			});
		}
	});
	return [updatedFormData, dependentFields];
};

export const createFormValues = (form, formFields) => {
	const formObject = {};
	formFields.map((field) => {
		const { fieldType, hidden, defaultValue, selectedOptions } = field;
		let fieldValue = null;
		switch (fieldType) {
			case FIELD_TYPES.MULTI_CHECKBOX:
				fieldValue = Array.from(form?.elements)
					.filter(
						(element) =>
							element.type === fieldType &&
							element.name === field["name"] &&
							element.checked,
					)
					.map((element) => element.value)
					?.join(";");
				break;
			case FIELD_TYPES.RADIO:
				fieldValue = Array.from(form?.elements)
					.filter(
						(element) =>
							element.type === fieldType &&
							element.name === field["name"] &&
							element.checked,
					)
					.map((element) => element.value)?.[0];
				break;
			case FIELD_TYPES.SINGLE_CHECKBOX:
				fieldValue = Array.from(form?.elements).filter(
					(element) => element.name === field["name"],
				)?.[0]?.checked;
				break;
			case FIELD_TYPES.PHONE:
				const dialCode = form["dialcode"]?.value || "";
				const phoneNo = form[field["name"]]?.value;
				fieldValue = phoneNo ? `${dialCode}${phoneNo}` : "";
				break;
			case FIELD_TYPES.SELECT:
			case FIELD_TYPES.TEXT:
			case FIELD_TYPES.NUMBER:
			case FIELD_TYPES.TEXTAREA:
				fieldValue = form[field["name"]]?.value;
				break;
		}
		if (hidden) {
			switch (fieldType) {
				case FIELD_TYPES.SELECT:
					fieldValue = selectedOptions?.[0] || "";
				case FIELD_TYPES.SINGLE_CHECKBOX:
					fieldValue = selectedOptions?.[0] || false; //should we send a false value or a not assigned/ empty value here?
					break;
				case FIELD_TYPES.MULTI_CHECKBOX:
					fieldValue = selectedOptions.length ? selectedOptions?.join(";") : "";
					break;
				case FIELD_TYPES.RADIO:
				case FIELD_TYPES.TEXT:
				case FIELD_TYPES.NUMBER:
				case FIELD_TYPES.PHONE:
				case FIELD_TYPES.TEXTAREA:
					fieldValue = defaultValue || "";
					break;
			}
		}
		formObject[field["name"]] = fieldValue;
	});
	return formObject;
};

export const prepareLeadParamFields = (context) => {
	const leadParams = {
		gclid: getGclid(),
		segmentanonymousid: getSegmentAnonymousId(),
		uuid: getUniqueUserId(),
		variationid: getVwoCampaignId(),
	};

	const utmParams = GetReferrerUtmParams();
	Object.entries(utmParams).forEach(([key, value]) => {
		leadParams[key] = value;
	});

	const [referrer, referrer2] = GetReferrer();
	if (context?.dripTagPrefix) {
		leadParams[`${context?.dripTagPrefix}_referral`] = referrer;
		leadParams[`${context?.dripTagPrefix}_referral1`] = referrer2;
	} else {
		leadParams["referral"] = referrer;
	}

	return Object.entries(leadParams).reduce((acc, [key, value]) => {
		if (value) {
			acc.push({
				objectTypeId: OBJECT_TYPE_ID,
				name: key,
				value: value,
			});
		}
		return acc;
	}, []);
};

/**
 * Prepares the payload for the Hubspot form Interested API.
 *
 * @param {Object} formValues - values from the form user submitted.
 * @param {Object} context - context object.
 * @param {string} context.workshop_id - workshop id of the course.
 * @param {string} context.dripTagPrefix - drip tag prefix (shortname) of the course.
 *
 * @returns {Object} The prepared payload which includes the following fields:
 * - email: The provided email address.
 * - first_name: The provided first name.
 * - last_name: The provided last name.
 * - phone_no: The provided phone number.
 * - workshop_id: The workshop ID of the course.
 * - drip_tag_prefix: The drip tag prefix (shortname) of the course.
 * - uuid: A unique user ID.
 * - variation_id: The VWO campaign ID.
 * - segment_anonymous_id: The Segment anonymous ID.
 * - lead_type: The lead type (always set to "AP").
 * - referral
 * - referral2
 */
export const prepareInterestedApiPayload = (formValues, context) => {
	const fieldsToAppend = {
		email: formValues?.email,
		first_name: formValues?.firstname,
		last_name: formValues?.lastname,
		phone_no: formValues?.phone,
		workshop_id: context?.workshop_id,
		drip_tag_prefix: context?.dripTagPrefix,
		uuid: getUniqueUserId(),
		variation_id: getVwoCampaignId(),
		segment_anonymous_id: getSegmentAnonymousId(),
		lead_type: HS_APP_LEAD_TYPE,
	};

	const [referrer, referrer2] = GetReferrer();
	fieldsToAppend["referral"] = referrer;
	fieldsToAppend["referral2"] = referrer2;

	const payload = {};
	Object.entries(fieldsToAppend).forEach(([key, value]) => {
		if (value !== undefined) {
			payload[key] = value;
		}
	});

	return payload;
};

/**
 * Prepare payload for App Started Interested API
 * @param {string} email - email address of user
 * @param {Object} context - context object
 * @param {string} context.workshop_id - workshop id of the course
 * @param {string} context.dripTagPrefix - drip tag prefix (shortname) of the course
 *
 * @returns {FormData} The prepared payload which includes the following fields:
 * - email: The provided email address.
 * - workshop_id: The workshop id of the course
 * - drip_tag_prefix: The drip tag prefix (shortname) of the course
 * - uuid: A unique user id
 * - apply: Always set to 1
 * - js_disabled: Always set to yes
 *
 */
export const prepareAppStartedPayload = (email, context) => {
	var payload = new FormData();
	payload.append("email", email);
	payload.append("workshop_id", context?.workshop_id);
	payload.append("drip_tag_prefix", context?.dripTagPrefix);
	payload.append("uuid", getUniqueUserId());
	payload.append("apply", 1);
	payload.append("js_disabled", "yes");

	return payload;
};

export const hasCountryCodes = (metaData = []) => {
	return metaData.find((item) => item.name === "useCountryCodeSelect")
		?.value === "true"
		? true
		: false;
};

const redirect = ({
	actionOnSubmit = "",
	submitButtonConfig = {},
	submitRedirectLink = {},
	pageSection = "",
}) => {
	if (actionOnSubmit === "redirect") {
		handleCTAClick(
			{
				...submitButtonConfig,
				link: {
					type: submitRedirectLink?.type,
					link: submitRedirectLink?.link,
					target: submitRedirectLink?.target,
				},
			},
			{
				page_section: pageSection,
				click_text: submitButtonConfig?.buttonText,
				redirect_to: submitRedirectLink?.link,
			},
		);
	}
};

export const callEventForStagedForm = (
	eventName = "",
	eventData = {},
	redirectConfig,
) => {
	// Extract proper eventData for Staged Form B2U
	if (eventData?.track) {
		eventData = eventData?.track;
	}
	callEventAndRedirect(eventName, eventData, redirectConfig);
};

const handleRedirect = (redirectConfig) => {
	const { submitRedirectLink = {}, email = "" } = redirectConfig;
	if (submitRedirectLink?.type === "typeform") {
		const uuid = getUniqueUserId();
		openTypeFormURLWithParameters(
			submitRedirectLink?.link,
			email,
			uuid,
			submitRedirectLink?.target,
		);
	} else {
		redirect(redirectConfig);
	}
};

export const callEventAndRedirect = (
	eventName = "",
	eventData = {},
	redirectConfig,
) => {
	const shouldRedirect =
		redirectConfig && redirectConfig.actionOnSubmit === "redirect";
	const eventCallback = shouldRedirect
		? () => {
				setTimeout(
					() => handleRedirect(redirectConfig),
					WAIT_TIME_FOR_ANALYTICS,
				);
			}
		: () => {};

	// When GTM does not exist, redirect to thank you page anyway
	if (!window["google_tag_manager"]) {
		console.error("GTM DOESN'T EXIST!");
		if (shouldRedirect) {
			handleRedirect(redirectConfig);
		}
		return;
	}

	//Trigger GA4 and Segment Event through GTM
	addToDataLayer({
		event: eventName,
		...eventData,
		eventCallback,
		eventTimeout: GTM_EVENT_TIMEOUT,
	});
};

export const getFormStyles = (isB2U, fieldsForFormstyles) => {
	let formStyles;

	if (isB2U) {
		const { formBackgroundColor, hasDarkBackground } = fieldsForFormstyles;

		formStyles = {
			formBg: formBackgroundColor && formatColor(formBackgroundColor),
			prgressBarBg:
				formBackgroundColor?.type === "default"
					? STAGED_FORM_COLORS.PROGRESS_BAR.BG_ON_WHITE
					: hasDarkBackground
						? STAGED_FORM_COLORS.PROGRESS_BAR.BG_LIGHT
						: STAGED_FORM_COLORS.PROGRESS_BAR.DEFAULT,
			textColor: hasDarkBackground
				? STAGED_FORM_COLORS.TEXT.LIGHT
				: STAGED_FORM_COLORS.TEXT.DEFAULT,
			linkColor: hasDarkBackground
				? STAGED_FORM_COLORS.LINK.ON_DARK
				: STAGED_FORM_COLORS.LINK.ON_LIGHT,
			darkBg: hasDarkBackground,
		};
	} else {
		const { formBg } = fieldsForFormstyles;
		const bgType = formBg;

		formStyles = {
			formBg:
				STAGED_FORM_COLORS.BG[bgType?.toUpperCase()] ||
				STAGED_FORM_COLORS.BG.DEFAULT,
			prgressBarBg: STAGED_FORM_COLORS.PROGRESS_BAR.DEFAULT,
			textColor: STAGED_FORM_COLORS.TEXT.DEFAULT,
			linkColor: STAGED_FORM_COLORS.LINK.ON_LIGHT,
			darkBg: false,
		};
		if (bgType === STAGED_FORM_BG_TYPES.FOREST_GREEN) {
			formStyles.prgressBarBg = STAGED_FORM_COLORS.PROGRESS_BAR.BG_LIGHT;
			formStyles.textColor = STAGED_FORM_COLORS.TEXT.LIGHT;
			formStyles.linkColor = STAGED_FORM_COLORS.LINK.ON_DARK;
			formStyles.darkBg = true;
		} else if (bgType === STAGED_FORM_BG_TYPES.PALE_GREEN) {
			formStyles.prgressBarBg = STAGED_FORM_COLORS.PROGRESS_BAR.BG_LIGHT;
		} else if (bgType === STAGED_FORM_BG_TYPES.WHITE) {
			formStyles.prgressBarBg = STAGED_FORM_COLORS.PROGRESS_BAR.BG_ON_WHITE;
		}
	}

	return formStyles;
};

export const modifyFormValuesForB2U = (formValues) => {
	const [referral, referral2] = GetReferrer();
	const activeCampaignFieldValues = ACTIVE_CAMPAIGN_FIELD_VALUES;

	Object.entries(formValues).forEach(([key, value]) => {
		if (key?.endsWith?.("referral")) {
			activeCampaignFieldValues[key] = value;
			formValues[key] = referral;
		}
		if (key?.endsWith?.("referral1")) {
			activeCampaignFieldValues[key] = value;
			formValues[key] = referral2;
		}
		if (key?.endsWith?.("variationid")) {
			formValues[key] = getVwoCampaignId();
		}
	});

	return [formValues, activeCampaignFieldValues];
};
