import styled from "styled-components";

export const Container = styled.div`
	position: relative;
	width: 100%;
	padding-bottom: 56%;
	height: 0;

	iframe,
	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;
