import styled from "styled-components";
// tab label variant based  style values
export const TabLabelFormat = {
	default: {
		selected: {
			font: "paragraph_L_bold",
			color: { base: "primary", color: "neutral-nightshade", shade: "800" },
		},
		default: {
			font: "paragraph_L",
			color: { base: "primary", color: "neutral-nightshade", shade: "800" },
		},
	},
	withIcon: {
		selected: {
			font: "headline_5",
			color: { base: "primary", color: "neutral-nightshade", shade: "800" },
		},
		default: {
			font: "headline_5",
			color: { base: "secondary", color: "gray", shade: "dark" },
		},
	},
};

export const VerticalTabItemsNameWrapper = styled.button`
	background: transparent;
	box-shadow: none;
	width: 100%;
	border: none;
	text-align: left;
	// removes default button styling

	cursor: pointer;
	max-height: 35px;
	${(props) =>
		props.selected &&
		` border-left: 0.5rem solid #11af00;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  `}

	${(props) =>
		props.variant === "withIcon" &&
		`display:flex;
    align-items:center;
    padding: 0 1.5rem;
     `}

     ${(props) =>
		props.selected &&
		` animation: slideInTab 0.6s ease-in-out;
    @keyframes slideInTab {
    0% {
      opacity:0.5;
      border-left: 0.2rem;
    }
    100% {
       opacity:1;
      border-left: 0.5rem solid #11af00;
    }
  }
  `}
`;

export const VerticalTabIconWrapper = styled.figure`
	margin: 0;
	display: inline-block;
	line-height: 0;
	.list-item-icon > svg {
		animation: slideIcon 0.8s ease-in;
		@keyframes slideIcon {
			0% {
				opacity: 0;
				width: 1.5rem;
				visibility: hidden;
			}
			100% {
				opacity: 1;
				width: 2rem;
				visibility: show;
			}
		}
	}
`;
export const VerticalTabLabelWrapper = styled.div`
	${(props) =>
		props.variant === "withIcon" &&
		` padding: 0 0.5rem;
  `}
	${(props) =>
		props.variant === "withIcon" &&
		!props.icon &&
		props.selected &&
		` padding: 0;
  `}

  ${(props) =>
		props.variant === "default" &&
		props.selected &&
		`padding: 0 0.5rem;
  `}
`;

// Tabs
export const VerticalTabContentWrapper = styled.div`
	animation: slideContent 0.8s ease-in;
	@keyframes slideContent {
		0% {
			opacity: 0.2;
		}
		100% {
			opacity: 1;
		}
	}
`;

export const VerticalTabsPanelWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1.5rem;
	position: sticky;
	top: 10rem;
	border-radius: 1rem;
	z-index: 2;
	${(props) =>
		props.variant === "withIcon" &&
		`  background-color: var(--secondary-gray-x-light);
    padding: 2.5rem 0;
  `}
`;

export const VerticalTabsHeadingWrapper = styled.div`
	${(props) =>
		props.variant === "withIcon" &&
		`padding: 0 2rem
     `}
`;
export const BottomAddOnWrapper = styled.div`
	margin: 0 2rem;
`;
