import React from "react";
import { Container, BlogVideoContainer, Subtitle } from "./styles";
import VideoElement from "../Elements/VideoElement";

const BlogVideo = ({ url, subtitle }) => {
	return (
		<Container>
			<BlogVideoContainer>
				<VideoElement url={url} />
			</BlogVideoContainer>
			<Subtitle>{subtitle}</Subtitle>
		</Container>
	);
};

export default BlogVideo;
