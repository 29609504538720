import React from "react";
import { Wrapper } from "./style";

const Column = ({ columnWidth, children, className }) => {
	return (
		<Wrapper
			className={className}
			desktop={columnWidth.desktop}
			tablet={columnWidth.tablet}
		>
			{children}
		</Wrapper>
	);
};

export default Column;
