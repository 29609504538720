import React from "react";
import { BreadCrumbsWraaper } from "./style";
import CtaLink from "./../CtaLink";

/**
 *
 * @param {Array} list
 *
 * @example
 * list = [
 *  {
 *      "label": "Home",
 *      "link":{
 *          "type": "hash" | "external" | "internal"
 *          "value": "<YOUR-VALUE>" //href value
 *          "target": "<YOUR-VALUE>"
 *          "className": "<YOUR-VALUE>"
 *       }
 *  }
 * ]
 */
const BreadCrumbs = ({ list = [] }) => {
	const renderItems = () => {
		return list.map(({ label, link }, index) => {
			return (
				<li key={index}>
					<CtaLink {...link}>{label}</CtaLink>
					{index !== list.length - 1 && <span>/</span>}
				</li>
			);
		});
	};

	return <BreadCrumbsWraaper>{renderItems()}</BreadCrumbsWraaper>;
};

export default BreadCrumbs;
