import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	Form,
	Icon,
	Input,
	Text,
	Link,
	Select,
	Boolean,
} from "@website-builder/ui/shared/elements";
import { PRODUCTION_API_URL } from "@website-builder/ui/shared/constants";
import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
import { formatColor } from "@website-builder/utilities/utils/DesignUtils.js";
import { isBusinessVerticalB2U } from "@website-builder/utilities/utils/helpers.js";
import { validateEmail } from "@website-builder/utilities/utils/validateEmail.js";
import { DIAL_CODES } from "@website-builder/utilities/constants/phoneCountryCodes.js";
import { TEXT_COLORS } from "@website-builder/utilities/constants/style-constants/colors.js";
import { LoaderWrapper, PhoneInputWrapper } from "./styles";
import { ErrorSection } from "../styles";
import { FIELD_TYPES, FILTER_TYPES, FieldProps, HS_API } from "../constants";
import {
	transformHubspotFormData,
	createFormValues,
	hasCountryCodes,
} from "../utils";

const isB2U = isBusinessVerticalB2U();
const HSForm = ({
	formID,
	takeUserConsent = isB2U ? false : true,
	consentText,
	context,
	cachedFormData = [],
	darkBg = false,
	itemsOnFirstColumn = 0,
	children,
	onSubmit = () => {},
	isMobileView = false,
	backgroundColor = {},
}) => {
	// const styleConfiguration = formDetails?.styleConfiguration?.[0] || {};
	const styleConfiguration = {};
	const { apiBaseUrl } = context;

	const [error, setError] = useState({});
	const [showErrors, setShowErrors] = useState(false);
	const [formFields, setFormFields] = useState([FieldProps]);
	const [isFormValid, setIsFormValid] = useState(true);
	const [formInputError, setFormInputError] = useState("");
	const [loading, setLoadingState] = useState(true);
	const [dependendFields, setDependendFields] = useState([]);
	const [dialCode, setDialCode] = useState("+1");
	const agreementCheckboxRef = useRef();
	const textColor = !darkBg ? TEXT_COLORS.TEXT_DARK : TEXT_COLORS.TEXT_LIGHT;
	const apiBaseUrlString = isB2U ? PRODUCTION_API_URL : apiBaseUrl;

	useEffect(() => {
		const updatedFormFields = [...formFields];
		if (updatedFormFields.length > dependendFields.length) {
			dependendFields.forEach(({ index, isDepHidden }) => {
				updatedFormFields[index].isDepHidden = isDepHidden;
			});
			setFormFields(updatedFormFields);
		}
	}, [dependendFields]);

	useEffect(() => {
		const formDetails = cachedFormData?.find?.(({ id }) => id === formID);
		if (formDetails?.data) {
			const [updatedFormData, dependentFields] = transformHubspotFormData(
				formDetails.data,
			);
			setFormFields(updatedFormData);
			setDependendFields(dependentFields);
			setLoadingState(false);
		} else {
			setLoadingState(true);
			const hsFormFieldsAPI = `${apiBaseUrlString}/${HS_API.GET_FORM}/${formID}/`;
			fetch(hsFormFieldsAPI)
				.then((res) => res.json())
				.then((response) => {
					const [updatedFormData, dependentFields] =
						transformHubspotFormData(response);
					setFormFields(updatedFormData);
					setDependendFields(dependentFields);
					setLoadingState(false);
				})
				.catch((err) => {
					setFormFields([]);
					setLoadingState(false);
					console.error(err);
				});
		}
	}, [formID]);

	const validateForm = (formValues) => {
		let isFormValid = true;
		let formInputError = "";
		const errors = formFields.reduce((acc, field) => {
			if (field.required && !formValues[field.name]) {
				isFormValid = false;
				acc[field.name] = "This is a required field.";
				if (!formInputError) {
					formInputError = "Please fill in all the required fields.";
				}
			}
			return acc;
		}, {});

		if (formValues?.email && validateEmail(formValues?.email) !== true) {
			isFormValid = false;
			errors.email = "Please enter a valid email.";
			formInputError = "Please enter a valid email.";
		}

		if (takeUserConsent) {
			const hasUserAgreed = agreementCheckboxRef.current?.checked || false;
			if (!hasUserAgreed) {
				isFormValid = false;
				formInputError = "Please agree to the terms and conditions.";
			}
		}

		setShowErrors(true);
		setIsFormValid(isFormValid);
		setFormInputError(formInputError);
		setError(errors);

		return isFormValid;
	};

	const handleFormSubmit = (form) => {
		const formValues = createFormValues(form, formFields) || {};
		const formValid = validateForm(formValues);
		if (!formValid) {
			return false;
		}
		onSubmit(formValues);
	};

	const handleDialCodeSelectChange = (event) => {
		setDialCode(event?.target?.value);
		Array.from(event.target.options).forEach((option) => {
			option.text = option.getAttribute("data-display");
		});
		event.target.options[event.target.selectedIndex].text = event.target.value;
	};

	const getFieldType = (field) => {
		if (field.name === "email") {
			return "email";
		}
		return field.fieldType;
	};

	const handleSelectChange = (event) => {
		const { value = "", name = "" } = event?.target || {};
		const updatedDependendFields = dependendFields.map((field) => {
			let isDepHidden = field.isDepHidden;
			if (field.parentName === name) {
				switch (field.operator) {
					case FILTER_TYPES.SET_ANY:
						isDepHidden = !field?.strValues?.some((val) => val === value);
						return { ...field, isDepHidden };
					case FILTER_TYPES.SET_NOT_ANY:
						isDepHidden = field?.strValues?.some((val) => val === value);
						return { ...field, isDepHidden };
					case FILTER_TYPES.IS_NOT_EMPTY:
						isDepHidden = value === ("" || null || undefined);
						return { ...field, isDepHidden };
					default:
						return field;
				}
			}
			return field;
		});
		setDependendFields(updatedDependendFields);
	};

	const errorSectionJSX = !isFormValid && (
		<ErrorSection className="form-bottom-error">
			<Icon iconName="warning" />
			<Text variant="paragraph_S" className="errorMessage">
				{formInputError}
			</Text>
		</ErrorSection>
	);

	const formConsent = () => {
		const consentTextJSX = !isB2U ? (
			<Text
				tag="span"
				variant="paragraph_S"
				color={textColor}
				className="form-consent-text-b2c"
			>
				{consentText} Please visit our{" "}
				<Link href="/legal/privacy/" target="_blank" linkText="Privay Policy">
					<Text variant="paragraph_S_bold" className="form-consent-link">
						{" "}
						Privacy Policy{" "}
					</Text>
				</Link>{" "}
				for more information.
			</Text>
		) : (
			<Text
				tag="span"
				variant="paragraph_S"
				color={textColor}
				dangerouslySetInnerHTML={{ __html: richTextResolver(consentText) }}
				className="form-consent-text-b2u"
				darkBg={darkBg}
			/>
		);

		return (
			<label className="form-consent">
				<input ref={agreementCheckboxRef} type="checkbox" />
				{consentTextJSX}
			</label>
		);
	};

	const generateFormJSXUsingFields = useCallback(
		(fields) => {
			return fields.map((field, index) => {
				if (!field.hidden && !field?.isDepHidden) {
					switch (field.fieldType) {
						case FIELD_TYPES.TEXT:
						case FIELD_TYPES.NUMBER:
						case FIELD_TYPES.TEXTAREA:
							return (
								<Input
									key={index}
									className={`hubspot-form-input-field ${
										field?.isHalfField ? "half-field" : ""
									}`}
									name={field.name}
									label={field.label}
									type={getFieldType(field)}
									showErrors={showErrors}
									required={field.required}
									hidden={field.hidden}
									error={[error, setError]}
									placeholder={field.placeholder}
									fieldType={field?.fieldType}
								/>
							);
						case FIELD_TYPES.PHONE:
							const includeDialCodes = hasCountryCodes(field?.metaData);
							return (
								<PhoneInputWrapper
									key={index}
									className={`hubspot-form-input-field phone-input ${
										field?.isHalfField ? "half-field" : ""
									}`}
									includeDialCodes={includeDialCodes}
								>
									<Text
										variant="label_M"
										color={textColor}
										className={`label-text ${field?.required && "required"}`}
									>
										{field?.label}
									</Text>
									<div className="phone-field-container">
										{includeDialCodes && (
											<Select
												name="dialcode"
												className="dial-code-input"
												value={dialCode}
												isPhoneInput={true}
												onChange={handleDialCodeSelectChange}
												isHubspotForm={true}
											>
												{DIAL_CODES.map((option, index) => (
													<option
														value={option?.dial_code}
														key={index}
														id={option?.code}
														data-display={`${option?.name} (${option?.dial_code})`}
													>
														{option?.dial_code !== dialCode
															? `${option?.name} (${option?.dial_code})`
															: option?.dial_code}
													</option>
												))}
											</Select>
										)}
										<Input
											key={index}
											className="phone-input"
											name={field.name}
											type="number"
											showErrors={showErrors}
											required={field.required}
											hidden={field.hidden}
											error={[error, setError]}
											placeholder={field.placeholder}
											fieldType={field?.fieldType}
										/>
									</div>
								</PhoneInputWrapper>
							);
						case FIELD_TYPES.MULTI_CHECKBOX:
						case FIELD_TYPES.SINGLE_CHECKBOX:
						case FIELD_TYPES.RADIO:
							return (
								<Boolean
									key={index}
									name={field?.name}
									className={`hubspot-form-input-field ${
										field?.isHalfField ? "half-field" : ""
									}`}
									options={field?.options}
									required={field?.required}
									helpText={field?.description}
									type={field?.fieldType}
									label={field?.label}
									error={[error, setError]}
									showErrors={showErrors}
									defaultValue={field?.selectedOptions}
									textColor={textColor}
								/>
							);
						case FIELD_TYPES.SELECT:
							return (
								<Select
									key={index}
									name={field?.name}
									className={`hubspot-form-input-field ${
										field?.isHalfField ? "half-field" : ""
									}`}
									label={field?.label}
									placeholder={field?.unselectedLabel || "Please Select"}
									required={field?.required}
									helpText={field?.description}
									showErrors={showErrors}
									error={[error, setError]}
									onChange={(event) => handleSelectChange(event, field?.name)}
									isHubspotForm={true}
								>
									{field?.options
										?.filter(({ hidden }) => !hidden)
										?.map((option, index) => (
											<option
												value={option?.value}
												key={index}
												id={option?.value}
											>
												{option?.label}
											</option>
										))}
								</Select>
							);
						default:
							return null;
					}
				}
			});
		},
		[error],
	);

	const generateCommonInputFieldElements = (field) => {
		return (
			<>
				{generateFormJSXUsingFields(field)}
				{takeUserConsent && formConsent()}
				{errorSectionJSX}
				{children}
			</>
		);
	};

	if (formFields.length > 0) {
		const firstFormColumnFields = formFields.slice(0, itemsOnFirstColumn);
		const secondFormColumnFields = formFields.slice(itemsOnFirstColumn);

		return (
			<>
				{loading ? (
					<LoaderWrapper darkBg={darkBg}>
						<div className="loader"></div>
					</LoaderWrapper>
				) : (
					<Form
						onSubmit={(e) => {
							e.preventDefault();
							handleFormSubmit(e.target);
						}}
						darkBg={darkBg}
						className="sb-hubspot-form"
					>
						{!!itemsOnFirstColumn ? (
							<div className="two-column-container">
								<div className="hubspot-form-column">
									{generateFormJSXUsingFields(firstFormColumnFields)}
								</div>
								<div className="hubspot-form-column">
									{generateCommonInputFieldElements(secondFormColumnFields)}
								</div>
							</div>
						) : (
							<>{generateCommonInputFieldElements(formFields)}</>
						)}
					</Form>
				)}
			</>
		);
	} else {
		return (
			// <ErrorSection isBoxShadow={!formDetails?.removeFormShadow} isMobileView={isMobileView} bgColor={backgroundColor?.color || formatColor(styleConfiguration?.backgroundColor)}>
			<ErrorSection
				isMobileView={isMobileView}
				bgColor={
					backgroundColor?.color ||
					formatColor(styleConfiguration?.backgroundColor)
				}
			>
				<Icon iconName="warning" size="32px" />
				<Text variant="headline_5" color={textColor}>
					Error fetching the form!
				</Text>
			</ErrorSection>
		);
	}
};

export default HSForm;
