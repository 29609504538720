import React from "react";

export const lineGraphicCollection = {
	condensed_loop: (
		<svg
			alt=""
			width="495"
			height="253"
			viewBox="0 0 495 253"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.87891 1.37256C62.236 146.366 148.086 250.982 250.195 250.982C387.422 250.982 317.959 42.9742 246.855 42.9742C169.106 42.9742 309.881 317.245 492.917 117.302"
				strokeWidth="4"
			/>
		</svg>
	),
	double_loop: (
		<svg
			alt=""
			width="364"
			height="415"
			viewBox="0 0 364 415"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M152.2 322.271C150.003 337.154 144.807 350.977 136.137 362.459C123.23 379.553 96.7882 400.588 58.5507 412.131M152.2 322.271C161.322 260.481 118.761 180.416 58.5515 173.726C-7.7908 166.356 -20.5583 260.655 50.8758 303.514C66.4301 312.966 108.963 325.228 152.2 322.271ZM152.2 322.271C212.479 318.148 274.126 284.443 265.935 172.294M266.154 175.983C265.599 165.564 264.313 154.143 262.219 142.292M262.219 142.292C251.283 80.377 218.316 6.70365 152.478 2.63277C86.1355 -4.73767 73.3679 89.5611 144.802 132.421C162.43 143.132 213.869 154.313 262.219 142.292ZM262.219 142.292C312.549 129.779 359.53 92.1245 361.544 2.63"
				strokeWidth="4"
			/>
		</svg>
	),
	double_switch: (
		<svg
			alt=""
			width="591"
			height="197"
			viewBox="0 0 591 197"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.54688 112.856C175.957 174.184 503.205 260.044 416.916 112.856C330.626 -34.3324 496.193 -3.53386 589.763 30.2639"
				strokeWidth="4"
			/>
		</svg>
	),
	long_s_curve: (
		<svg
			alt=""
			width="497"
			height="291"
			viewBox="0 0 497 291"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M417.236 4.55546C531.186 -12.0345 540.436 93.4255 302.306 131.515C64.1764 169.605 -19.8336 194.155 7.63637 289.555"
				strokeWidth="4"
			/>
		</svg>
	),
	loop_and_switch: (
		<svg
			alt=""
			width="381"
			height="457"
			viewBox="0 0 381 457"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M147.854 4.98428C329.477 -7.64773 396.128 33.9926 374.995 86.6363C335.335 185.431 2.73578 89.2419 2.73584 211.144C2.73588 300.013 298.38 393.366 298.38 300.465C298.38 202.573 22.1149 218.362 94.2233 455.649"
				strokeWidth="4"
			/>
		</svg>
	),
	offset_loop: (
		<svg
			alt=""
			width="477"
			height="288"
			viewBox="0 0 477 288"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.16797 285.787C133.008 115.127 242.638 80.2874 315.308 80.2874C426.948 80.2874 447.988 154.797 388.918 188.467C329.848 222.137 242.658 118.427 475.638 2.56744"
				strokeWidth="4"
			/>
		</svg>
	),
	s_curve_wide: (
		<svg
			alt=""
			width="371"
			height="366"
			viewBox="0 0 371 366"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.83984 298.667C155.5 427.877 328.55 352.037 186.21 183.697C45.8498 17.6871 267.88 -0.132878 370.88 2.82712"
				strokeWidth="4"
			/>
		</svg>
	),
	single_loop_short: (
		<svg
			alt=""
			width="551"
			height="364"
			viewBox="0 0 551 364"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.763672 124.677C246.46 115.825 324.737 361.445 193.141 361.445C16.8515 361.445 178.237 36.4382 550.568 2.90948"
				strokeWidth="4"
			/>
		</svg>
	),
	single_loop_wide: (
		<svg
			alt=""
			width="415"
			height="355"
			viewBox="0 0 415 355"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.56738 353.977C35.9674 233.817 103.997 199.577 169.397 199.577C234.797 199.577 325.377 252.057 282.517 292.347C216.947 353.977 119.267 2.37744 414.167 2.37744"
				strokeWidth="4"
			/>
		</svg>
	),
	single_loop_with_curl: (
		<svg
			alt=""
			width="543"
			height="344"
			viewBox="0 0 543 344"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M354.018 25.1211C397.228 2.21177 443.161 -8.29797 495.193 19.0353C541.412 43.3145 556.403 114.694 522.652 178.943C472.977 273.506 309.836 332.655 204.072 286.39C-17.4103 189.506 77.7626 7.00989 161.92 51.2185C246.077 95.4271 235.777 255.705 0.877956 341.298"
				strokeWidth="4"
			/>
		</svg>
	),
	single_loop: (
		<svg
			alt=""
			width="593"
			height="300"
			viewBox="0 0 593 300"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.54297 198.427C399.302 51.866 425.118 297.903 274.046 297.903C122.974 297.903 247.274 -9.14585 592.445 2.79125"
				strokeWidth="4"
			/>
		</svg>
	),
	small_loop: (
		<svg
			alt=""
			width="252"
			height="362"
			viewBox="0 0 252 362"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.918166 359.763C141.225 326.837 108.54 232.878 73.4631 241.711C43.3007 249.308 49.1813 311.579 119.701 311.579C197.826 311.579 243.266 224.847 248.847 169.435C252.72 117.258 219.405 35.9068 157.423 2.59143"
				strokeWidth="4"
			/>
		</svg>
	),
	squiggly: (
		<svg
			alt=""
			width="724"
			height="344"
			viewBox="0 0 724 344"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.20894 371.912C58.5798 237.225 137.762 182.603 212.023 184.553C326.659 187.563 454.179 363.718 564.31 280.571C644.597 219.956 616.191 55.133 552.848 77.8819C473.071 106.533 605.937 298.418 752.916 1.16335"
				strokeWidth="3"
			/>
		</svg>
	),
	squiggly_reverse: (
		<svg
			alt=""
			width="543"
			height="217"
			viewBox="0 0 543 217"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M-20.4633 1.52004C26.752 96.369 86.6821 132.159 140.362 127.22C223.225 119.595 307.195 -14.0145 390.889 40.9603C451.905 81.038 439.164 201.735 392.218 188.271C333.09 171.314 420.184 26.0604 540.73 234.32"
				strokeWidth="3"
			/>
		</svg>
	),
	swoop: (
		<svg
			alt=""
			width="258"
			height="119"
			viewBox="0 0 258 119"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M256.389 117.782C198.893 71.1927 212.508 -0.863594 238.172 10.8454C264.106 22.6774 237.639 66.7711 170.77 61.0936C111.729 56.0808 42.5221 31.064 1.54196 -6.80208"
				strokeWidth="2"
			/>
		</svg>
	),
};
