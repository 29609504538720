import styled from "styled-components";
import {
	COLOR_WHITE,
	DESKTOP_M_BP,
	DESKTOP_SM_BP,
	MOBILE_L_BP,
} from "@website-builder/utilities-index";

export const Wrapper = styled.section`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	background-color: ${({ backgroundColor }) => backgroundColor};
	padding: 50px 60px;
	color: ${({ fontColor }) => fontColor};
	@media only screen and (max-width: ${DESKTOP_M_BP - 1}px) {
		padding: 55px 40px 75px;
	}
	@media only screen and (max-width: ${DESKTOP_SM_BP - 1}px) {
		padding: 55px 20px 75px 20px;
	}
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	${({ backgroudImage }) => {
		if (backgroudImage) {
			return `background-image:url('${backgroudImage}');`;
		}
	}}
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		${(props) =>
			props.mobileBackground &&
			`background-image: url(${props.mobileBackground});`}
	}
`;
