import React from "react";
import { CloudinaryImage } from "@website-builder/ui/shared/elements";
import { SectionWrapper, ContentWrapper, PartnerShipWrapper } from "./styles";
import transform from "./transform";
import { bannerFields } from "@website-builder/utilities-index";

const Banner = ({
	header,
	subHeader,
	content,
	imageConfig,
	hasPartnerShip = false,
	partnerShipLogo = null,
	partnerShipText = "",
	mobileImageConfig,
	selectH1Header = "HEADER",
}) => {
	const getEelements = () => {
		return (
			<ContentWrapper>
				{header &&
					(selectH1Header === bannerFields.HEADER ? (
						<h1 className="header">{header}</h1>
					) : (
						<h2 className="header">{header}</h2>
					))}
				{subHeader &&
					(selectH1Header === bannerFields.SUB_HEADER ? (
						<h1 className="sub-header">{subHeader}</h1>
					) : (
						<h2 className="sub-header">{subHeader}</h2>
					))}
				{content &&
					(selectH1Header === bannerFields.CONTENT ? (
						<h1 className="content">{content}</h1>
					) : (
						<h3 className="content">{content}</h3>
					))}
				{hasPartnerShip && (
					<PartnerShipWrapper>
						<div className="partner-ship-text">{partnerShipText}</div>
						<CloudinaryImage
							url={partnerShipLogo.url}
							sizes={{
								default: {
									width: partnerShipLogo.width,
									height: partnerShipLogo.height,
								},
							}}
							quality="auto:best"
							fetchFormat="auto"
							dpr="auto"
						/>
					</PartnerShipWrapper>
				)}
			</ContentWrapper>
		);
	};
	return (
		<SectionWrapper
			desktopBackground={imageConfig?.lazyImageUrl}
			mobileBackground={mobileImageConfig?.lazyImageUrl}
			className="lazyload"
		>
			{getEelements()}
		</SectionWrapper>
	);
};

export const bannerTransform = transform;
export default Banner;
