import React, { useState, useEffect } from "react";
import { getCloudinaryUrl } from "@website-builder/utilities/utils/CloudinaryUrlGenerator.js";
import { getPictureSrcSet } from "@website-builder/utilities/utils/CloudinarySrcsetGenerator.js";
import { isSafariBrowser } from "@website-builder/utilities/utils/utils.js";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Helmet } from "react-helmet";

const CloudinaryImage = ({
	quality,
	fetchFormat,
	dpr,
	url,
	flags,
	className,
	sizes,
	lazyload,
	crop,
	mode = "limit",
	alt = "",
	mobileImageUrl,
	mobileAltTag = "",
	preload = false, // pass this for the components that are rendered initially in the viewport. Adds a link preload element in the head.
	dynamic,
}) => {
	const getImageAndFallBackURL = (imageURL) => {
		const [, cloudName, , source, version, ...pathParts] = imageURL
			.replace(/https:\/\/|http:\/\//gi, "")
			.split("/");

		const imagePath = pathParts
			.join("/")
			.replace(/\.(png|jpg|jpeg)$/g, ".webp");

		const width = sizes && sizes.default ? sizes.default.width : null,
			height = sizes && sizes.default ? sizes.default.height : null;

		const imageOptions = {
			crop: crop ? "crop" : mode,
			width,
			height,
			quality: quality || "auto",
			fetchFormat: fetchFormat || "webp",
			dpr: dpr || "auto",
			flags: flags || "lossy",
			version,
			source,
			cloudName,
		};

		const defaultImageUrl = getCloudinaryUrl(imagePath, {
			...imageOptions,
		});

		const lowResFallback = lazyload
			? getCloudinaryUrl(imagePath, {
					...imageOptions,
					width: 30,
				})
			: defaultImageUrl;

		return { defaultImageUrl, lowResFallback, imagePath, imageOptions };
	};

	const buildType = process.env.GATSBY_TEMPLATE;

	const {
		defaultImageUrl: baseDefaultImageUrl,
		lowResFallback: baseLowResFallback,
		imagePath,
		imageOptions,
	} = getImageAndFallBackURL(url);

	const [defaultImageUrl, setDefaultImageUrl] = useState(baseDefaultImageUrl);
	const [lowResFallback, setLowResFallback] = useState(baseLowResFallback);

	useEffect(() => {
		if (
			defaultImageUrl !== baseDefaultImageUrl ||
			lowResFallback !== baseLowResFallback
		) {
			setDefaultImageUrl(baseDefaultImageUrl);
			setLowResFallback(baseLowResFallback);
		}
	}, [baseDefaultImageUrl, baseLowResFallback]);

	useEffect(() => {
		//  for images that renedered conditionaly or for preview builds we need to refresh those img element to be captured by lozad
		if (window.lozad && (buildType === "PREVIEW" || dynamic)) {
			const observer = window.lozad(); // lazy loads elements with default selector as '.lozad'
			observer.observe();
		}
	}, []);

	let mobileImageUrlOptimised = "",
		mobileLowResFallback = "";
	if (mobileImageUrl) {
		const mobileImageData = getImageAndFallBackURL(mobileImageUrl);
		mobileImageUrlOptimised = mobileImageData.defaultImageUrl;
		mobileLowResFallback = mobileImageData.lowResFallback;
	}

	let srcSet;

	const isSrcSetProvided =
		sizes && Object.keys(sizes).filter((s) => s !== "default").length > 0;

	if (isSrcSetProvided) {
		srcSet = getPictureSrcSet(imagePath, imageOptions, sizes);
	}

	useEffect(() => {
		// safari webp fix
		if (isSafariBrowser()) {
			let safariLowResFallback = defaultImageUrl;
			if (lowResFallback.endsWith(".webp")) {
				safariLowResFallback = safariLowResFallback.replace(/\.webp$/g, ".png");
			}
			if (defaultImageUrl.endsWith(".webp")) {
				const safariDefaultImageUrl = defaultImageUrl.replace(
					/\.webp$/g,
					".png",
				);
				setDefaultImageUrl(safariDefaultImageUrl);
			}
			setLowResFallback(safariLowResFallback);
		}
	}, []);

	let imagePathFallback;
	if (!imagePath.endsWith(".svg")) {
		let splitOnPeriod = imagePath.split(".");
		splitOnPeriod[splitOnPeriod.length - 1] = "jpg";
		imagePathFallback = splitOnPeriod.join(".");
	}

	let srcSetFallback;
	if (isSrcSetProvided) {
		srcSetFallback = getPictureSrcSet(imagePathFallback, imageOptions, sizes);
	}
	// end of safari webp
	if (!url) return <></>;

	return (
		<picture>
			{srcSet &&
				srcSet.map(({ srcSet, media }, index) => {
					let props = {};

					if (lazyload) {
						props["data-srcset"] = srcSet;
					} else {
						props["srcSet"] = srcSet;
					}
					return (
						<source key={index} media={media} {...props} type="image/webp" />
					);
				})}
			{srcSetFallback &&
				srcSetFallback.map(({ srcSet, media }, index) => {
					let props = {};

					if (lazyload) {
						props["data-srcset"] = srcSet;
					} else {
						props["srcSet"] = srcSet;
					}
					return (
						<source key={index} media={media} {...props} type="image/jpg" />
					);
				})}
			{mobileImageUrlOptimised && (
				<source
					media="(max-width:540px)"
					srcSet={mobileLowResFallback}
					data-srcset={mobileImageUrlOptimised}
					alt={mobileAltTag || alt}
				/>
			)}
			{preload && (
				<Helmet>
					<link rel="preload" as="image" href={defaultImageUrl} />
				</Helmet>
			)}
			<img
				className={clsx(className, { lozad: lazyload })}
				data-src={defaultImageUrl}
				src={lazyload ? "" : defaultImageUrl}
				alt={alt}
			/>
		</picture>
	);
};

const size = PropTypes.shape({
	width: PropTypes.number,
	height: PropTypes.number,
});

CloudinaryImage.propTypes = {
	className: PropTypes.string,
	lazyload: PropTypes.bool,
	dpr: PropTypes.string,
	url: PropTypes.string.isRequired,
	quality: PropTypes.string,
	flags: PropTypes.string,
	sizes: PropTypes.shape({
		"322px": size,
		"540px": size,
		"768px": size,
		"992px": size,
		"1120px": size,
		"1200px": size,
		default: size,
	}),
	crop: PropTypes.bool,
	mode: PropTypes.string,
	preload: PropTypes.bool,
};

CloudinaryImage.defaultProps = {
	url: "",
	className: "",
	sizes: null,
	lazyload: false,
	flags: "",
	dpr: "",
	quality: "",
	fetchFormat: "",
	crop: false,
	mode: "limit",
	preload: false,
	dynamic: false,
};

export default CloudinaryImage;
