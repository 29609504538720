import styled from "styled-components";
import {
	FONT_SIZE_18,
	FONT_WT_REGULAR,
} from "@website-builder/utilities-index";

export const BreadCrumbsWraaper = styled.ul`
	font-size: ${FONT_SIZE_18};
	margin: 0px;
	padding: 0px;
	li {
		display: inline-block;
		span {
			margin: 0 5px;
		}
		a {
			color: inherit;
			text-decoration: none;
			text-transform: capitalize;
			&:hover {
				text-decoration: underline;
			}
		}
	}
`;
