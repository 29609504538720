import styled from "styled-components";

export const DropdownContent = styled.div`
	width: max-content;
	height: max-content;
	position: absolute;
	z-index: 2;
	background: var(--primary-neutral-white);
	border-radius: 16px;
	margin-top: 10px;
	box-sizing: border-box;
	visibility: hidden;
	top: ${({ dropdownOffset, contentTopAdjusted }) =>
		contentTopAdjusted ? contentTopAdjusted : dropdownOffset?.height}px;
	box-shadow:
		-24px 233px 94px rgba(19, 48, 22, 0.01),
		-14px 131px 79px rgba(19, 48, 22, 0.05),
		-6px 58px 58px rgba(19, 48, 22, 0.09),
		-2px 15px 32px rgba(19, 48, 22, 0.1),
		0px 0px 0px rgba(19, 48, 22, 0.1);
	&.animate-in {
		animation: slideIn 0.3s ease-in;
		@keyframes slideIn {
			from {
				height: 0;
				opacity: 0;
			}
			to {
				height: ${({ contentHeight }) => contentHeight + "px"};
				opacity: 1;
			}
		}
	}
	&.animate-out {
		animation: zoomOut 0.3s ease-out;
		@keyframes zoomOut {
			from {
				height: ${({ contentHeight }) => contentHeight + "px"};
				opacity: 1;
			}
			to {
				height: 0;
				opacity: 0;
			}
		}
	}
	&.hide {
		visibility: hidden !important;
	}
	&.show {
		visibility: visible !important;
		& .content {
			opacity: 1;
			transition: opacity 300ms ease-in;
			transition-delay: 300ms;
		}
	}
	& .content {
		opacity: 0;
		&.hide {
			visibility: hidden !important;
		}
	}

	${({ isContentMegaMenu }) =>
		isContentMegaMenu &&
		`
    left: -360px;
    @media only screen and (max-width: 1400px) {
      left: -320px;
    }
    @media (min-width: 1101px) and (max-width: 1300px) {
      left: -290px;
    }
    @media (min-width: 991px) and (max-width: 1100px) {
      left: -270px;
    }
  `};
`;

export const DropdownWrapper = styled.div`
	position: relative;
	display: inline-block;
`;

export const DropdownOverlay = styled.div`
	position: absolute;
	background: rgba(0, 0, 0, 0.3);
	top: ${({ dropdownOffset, overlayTopAdjusted }) =>
		overlayTopAdjusted ? overlayTopAdjusted : dropdownOffset?.height}px;
	height: ${({ contentOffset }) =>
		"calc(100vh - " + contentOffset?.y + "px + 10px)"};
	left: ${({ contentOffset }) => "-" + contentOffset?.x + "px"};
	right: ${({ contentOffset, dropdownOffset }) =>
		"calc(" +
		contentOffset?.x +
		"px + " +
		dropdownOffset?.width +
		"px - 100vw)"};
	opacity: ${({ open }) => (open ? 1 : 0)};
	transition: opacity 0.3s ease-in-out;
	display: ${({ isOpen }) => (!isOpen ? "none" : "")};

	${({ isContentMegaMenu }) =>
		isContentMegaMenu &&
		`
      margin-left: calc((100% - 100vw)/2);
      margin-right: calc((100% - 100vw)/2);
    `}
`;
