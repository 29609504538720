const apiBaseUrl = process.env.GATSBY_API_BASE_URL;
const workshopEnrollmentDetailsUrl = `${apiBaseUrl}/workshops/microsite/workshop-enrollment-details/`;

export const getProgramListInfo = (programList, contentType = "Program") => {
	return programList.map((item, i) => {
		let shortName = item.content.shortName && item.content.shortName.trim();
		return {
			queryKey: [contentType, item.content?.programName],
			queryFn: () =>
				fetch(`${workshopEnrollmentDetailsUrl}${shortName}/`)
					.then((res) => res.json())
					.then((data) => {
						return {
							name: item.content?.programName,
							content: data,
						};
					}),
			enabled: programList.length > 0,
			refetchOnMount: false,
		};
	});
};

export const getProgramInfo = (shortName, programName) => {
	return () =>
		fetch(`${workshopEnrollmentDetailsUrl}${shortName}/`)
			.then((res) => res.json())
			.then((data) => {
				return {
					name: programName,
					content: data,
				};
			})
			.catch((error) => {
				return { error };
			});
};
