import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import {
	HorizontalList,
	Text,
	CloudinaryImage,
} from "@website-builder/ui/shared/elements";

import { Image } from "./styles";

const AlumniBar = forwardRef(
	({ listItems, labelConfig, ...restProps }, ref) => {
		const text = labelConfig.hasOwnProperty("text") ? labelConfig.text : "";
		const variant = labelConfig.hasOwnProperty("variant")
			? labelConfig.variant
			: "label_M";
		const color = labelConfig.hasOwnProperty("color")
			? labelConfig.color
			: "var(--secondary-gray-dark)";

		const images = (
			<HorizontalList
				gap="0.5rem"
				{...restProps}
				listItems={listItems.map((item, index) => (
					<Image key={index}>
						<CloudinaryImage
							url={item.url}
							alt={item.alt}
							key={index}
							sizes={{ default: { height: 100 } }}
							lazyload
						/>
					</Image>
				))}
			/>
		);

		return (
			<div className="alumni-bar">
				<HorizontalList
					gap="1rem"
					listItems={[
						images,
						<Text
							variant={variant}
							style={{ color: color, lineHeight: "3rem" }}
						>
							{text}
						</Text>,
					]}
					ref={ref}
					{...restProps}
				/>
			</div>
		);
	},
);

AlumniBar.defaultProps = {
	labelConfig: {},
	listItems: [],
};

export default AlumniBar;
