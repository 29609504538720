import styled from "styled-components";
import {
	DESKTOP_SM_BP,
	TABLET_BP,
	MOBILE_L_BP,
	COLOR_WHITE,
	FONT_WT_HEAVY,
	FONT_WT_REGULAR,
} from "@website-builder/utilities-index";

export const ContentWrapper = styled.div`
	color: ${COLOR_WHITE};
	padding: 147px 120px;
	text-align: left;
	@media only screen and (max-width: ${DESKTOP_SM_BP}px) {
		padding: 147px 30px;
	}
	@media only screen and (max-width: ${TABLET_BP}px) {
		padding: 147px 30px;
	}
	@media only screen and (max-width: ${MOBILE_L_BP - 1}px) {
		padding: 100px 16px;
	}
	.header {
		font-size: 42px;
		margin: 0 0 15px 0;
		width: 600px;
		font-weight: ${FONT_WT_HEAVY};
		@media only screen and (max-width: ${MOBILE_L_BP - 1}px) {
			width: 100%;
			font-size: 28px;
		}
	}
	.sub-header {
		font-size: 28px;
		margin: 0 0 15px 0;
		width: 600px;
		font-weight: ${FONT_WT_HEAVY};
		@media only screen and (max-width: ${MOBILE_L_BP - 1}px) {
			width: 100%;
			font-size: 24px;
		}
	}
	.content {
		font-size: 16px;
		margin: 0 0 10px 0;
		line-height: 1.38;
		text-align: left;
		width: 600px;
		font-weight: normal;
		@media only screen and (max-width: ${MOBILE_L_BP - 1}px) {
			width: 100%;
		}
	}
`;

export const SectionWrapper = styled.div`
	${(props) =>
		props.desktopBackground &&
		`background-image: url(${props.desktopBackground});`}
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		${(props) =>
			props.mobileBackground &&
			`background-image: url(${props.mobileBackground});`}
	}
`;

export const PartnerShipWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-top: 40px;
	div.partner-ship-text {
		margin-right: 20px;
		font-weight: ${FONT_WT_REGULAR};
	}
	@media only screen and (max-width: ${MOBILE_L_BP - 1}px) {
		flex-direction: column;
		align-items: flex-start;
		div.partner-ship-text {
			margin-bottom: 20px;
		}
	}
`;
