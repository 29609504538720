import loadable from "@loadable/component";

export const CloudinaryImage = loadable(() => import("./CloudinaryImage"));
export const Text = loadable(() => import("./Text"));
export const MixedString = loadable(() => import("./MixedString"));
export const Badge = loadable(() => import("./Badge"));
export const Accordion = loadable(() => import("./Accordion"));
export const Separator = loadable(() => import("./Separator"));
export const VerticalList = loadable(() => import("./VerticalList"));
export const HorizontalList = loadable(() => import("./HorizontalList"));
export const Card = loadable(() => import("./Card"));
export const Button = loadable(() => import("./Button"));
export const ButtonWithLoader = loadable(() => import("./ButtonWithLoader"));
export const Link = loadable(() => import("./Link"));
export const Icon = loadable(() => import("./Icon"));
export const LineGraphic = loadable(() => import("./LineGraphic"));
export const CommonLines = loadable(() => import("./LineGraphic/CommonLines"));
export const InputWithButton = loadable(() => import("./InputWithButton"));
export const Form = loadable(() => import("./Form"));
export const Input = loadable(() => import("./Form/Input"));
export const Select = loadable(() => import("./Form/Select"));
export const Boolean = loadable(() => import("./Form/Boolean"));
export const Slider = loadable(() => import("./Slider"));
export const VideoButton = loadable(() => import("./VideoButton"));
export const VideoElement = loadable(() => import("./Video"));
export const VideoPlayer = loadable(() => import("./VideoPlayer"));
export const Grid = loadable(() => import("./Grid"));
export const Col = loadable(() => import("./Grid/Col"));
export const Rating = loadable(() => import("./Rating"));
export const Modal = loadable(() => import("./Modal"));
export const InterestedContactForm = loadable(
	() => import("./InterestedContactForm"),
);
export const RequestSyllabusButton = loadable(
	() => import("./RequestSyllabusButton"),
);
export const VerticalTab = loadable(() => import("./VerticalTab"));
export const CallUsModal = loadable(() => import("./CallUsModal"));
export const AuthenticationModal = loadable(
	() => import("./AuthenticationModal"),
);
export const VerticalTabItem = loadable(
	() => import("./VerticalTab/VerticalTabItem"),
);
export const HubspotForm = loadable(() => import("./HubspotForm"));
export const HubspotStagedForm = loadable(
	() => import("./HubspotForm/StagedForm"),
);
export const Image = loadable(() => import("./Image"));
export const PricingAccordion = loadable(() => import("./PricingAccordion"));
export const ProgramInfo = loadable(() => import("./ProgramInfo"));
export const LearningPathPopup = loadable(() => import("./LearningPathPopup"));
export const Popup = loadable(() => import("./Popup"));
export const ApplicationForm = loadable(() => import("./ApplicationForm"));
export const LeadForm = loadable(() => import("./LeadForm"));
export const ModalForm = loadable(() => import("./ModalForm"));
export const StarRating = loadable(() => import("./StarRating"));
export const StarRatingWithLogo = loadable(
	() => import("./StarRatingWithLogo"),
);
export const ReviewCard = loadable(() => import("./ReviewCard"));
export const UserInfo = loadable(() => import("./UserInfo"));
