import styled from "styled-components";

import {
	TABLET_BP,
	MOBILE_L_BP,
	COLOR_WHITE,
} from "@website-builder/utilities-index";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const BlogVideoContainer = styled.div`
	background: ${COLOR_WHITE};
	width: calc(100% - 20px);
	max-width: 500px;
	@media only screen and (max-width: ${TABLET_BP}px) {
		width: calc(100% - 10px);
		margin-top: 10px;
	}
`;

export const Subtitle = styled.div`
	border-radius: 3px;
	padding: 13px 13px 0px;
	line-height: 19px;
	text-align: center;
	max-width: 500px;
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		padding: 7px 7px 0px 7px;
	}
`;
