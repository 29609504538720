import React from "react";
import { Wrapper } from "./style";
import Column from "./../../../core/sections/Column";
import columnTransform from "./../../../core/sections/Column/tansform";
import Components, { ComponentTransform } from "./../../../components";

const CustomFooter = ({
	backgroudImageConfig = null,
	backgroundColor = "",
	fontColor = "",
	hashLink = "",
	columns = [],
	basicConfig,
	mobileBackgroundImageConfig = null,
}) => {
	const constructColumn = () => {
		return columns.map((column, index) => {
			const props = columnTransform(column);
			return (
				<Column {...props} key={`column-${index}`}>
					{constructComponents(column.components)}
				</Column>
			);
		});
	};

	const constructComponents = (components) => {
		return components.map(({ component: name, ...props }, index) => {
			const Component = Components[name];
			let componentProps = {};
			if (ComponentTransform[name]) {
				componentProps = ComponentTransform[name](props, { basicConfig });
			} else {
				componentProps = props;
			}
			if (Component) {
				return (
					<Component
						key={index}
						{...componentProps}
						basicConfig={basicConfig}
						fontColor={fontColor}
					></Component>
				);
			} else {
				console.error(`${name} is not difined in the Components`);
				new Error(`${name} is not difined in the Components `);
			}
		});
	};
	return (
		<Wrapper
			backgroudImage={backgroudImageConfig?.image}
			mobileBackground={mobileBackgroundImageConfig?.image}
			backgroundColor={backgroundColor}
			fontColor={fontColor}
			id={hashLink}
		>
			{constructColumn()}
		</Wrapper>
	);
};

export default CustomFooter;
