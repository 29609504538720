// import Button from "../Button";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { DropdownWrapper, DropdownContent, DropdownOverlay } from "./style";
import FocusLock from "react-focus-lock";
import clsx from "clsx";

const Dropdown = ({
	ctaComponent,
	onClose,
	content,
	contentId = null,
	open,
	contentTop,
	overlayTop,
	...restProps
}) => {
	const wrapperRef = useRef(null);
	const contentRef = useRef(null);
	const ctaRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const contentHeight = useRef();
	const contentOffset = useRef(null);
	const dropdownOffset = useRef(null);
	const contentTopAdjusted = useRef(contentTop);
	const overlayTopAdjusted = useRef(overlayTop);

	useEffect(() => {
		// If the user clicks outside the dropdown, the dropdown closes.
		const handleExit = (event) => {
			if (
				isOpen &&
				wrapperRef.current &&
				!wrapperRef.current.contains(event.target)
			) {
				onClose && onClose();
			}
		};

		document.addEventListener("click", handleExit);
		return () => {
			document.removeEventListener("click", handleExit);
		};
	}, [onClose]);

	useEffect(() => {
		if (open) {
			setIsOpen(open);
		} else {
			setTimeout(() => {
				setIsOpen(open);
			}, 300);
		}
		window.document.body.style.overflow = open ? "hidden" : "unset";
	}, [open]);

	useMemo(() => {
		if (contentRef.current && !contentHeight.current) {
			window.requestAnimationFrame(() => {
				const { height, x, y, width } =
					contentRef.current.getBoundingClientRect();
				contentHeight.current = height;
				contentOffset.current = { x, y, width, height };
			});
		}
	}, [contentRef.current]);

	useMemo(() => {
		if (ctaRef.current && !dropdownOffset.current) {
			window.requestAnimationFrame(() => {
				const { height, x, y, width } = ctaRef.current.getBoundingClientRect();
				dropdownOffset.current = { x, y, height, width };
				contentTopAdjusted.current += height;
				overlayTopAdjusted.current += height;
			});
		}
	}, [ctaRef.current]);

	const enhancedContent = useMemo(
		() =>
			React.Children.map(content, (child) => {
				if (content && typeof content === "object") {
					return React.cloneElement(child, {
						className: clsx(!open && "hide", "content"),
					});
				}
				return null;
			}),
		[content, open],
	);

	const enhancedCtaComponent = useMemo(
		() =>
			React.Children.map(ctaComponent, (child) => {
				if (ctaComponent && typeof ctaComponent === "object") {
					return React.cloneElement(child, {
						ref: ctaRef,
					});
				}
				return null;
			}),
		[ctaComponent],
	);

	return (
		<FocusLock disabled={!open}>
			<DropdownWrapper ref={wrapperRef}>
				<DropdownOverlay
					overlayTopAdjusted={overlayTopAdjusted.current}
					contentOffset={contentOffset.current}
					dropdownOffset={dropdownOffset.current}
					open={open}
					isOpen={isOpen}
					onClick={onClose}
					{...restProps}
				/>
				{enhancedCtaComponent}
				<DropdownContent
					contentTopAdjusted={contentTopAdjusted.current}
					dropdownOffset={dropdownOffset.current}
					contentHeight={contentHeight.current}
					ref={contentRef}
					open={open}
					role="dialog"
					id={contentId}
					{...restProps}
					className={clsx(
						"dropdown-content",
						open && "animate-in",
						!open && "animate-out",
						isOpen ? "show" : "hide",
					)}
				>
					{enhancedContent}
				</DropdownContent>
			</DropdownWrapper>
		</FocusLock>
	);
};

export default Dropdown;
