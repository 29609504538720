import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBars,
	faTimes,
	faPlus,
	faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { AElement, CtaLink } from "../../Elements";
import { CloudinaryImage } from "@website-builder/ui/shared/elements";
import {
	HeaderWrapper,
	SiteFloatingHeader,
	SiteFloatingHeaderMobile,
	StickyHeaderWrapper,
	SiteHeader,
	FloatingHeaderText,
	FloatingHeaderContent,
	HeaderNavigationsWrapper,
	HeaderNavigation,
	DropdownMenu,
	DropdownLink,
	DropdownLinkMobile,
} from "./styles";
import { Logo } from "./../../styles";

import { transform } from "./transform";

const Header = (props) => {
	const {
		logoConfig,
		hasNavigations = false,
		navigations = [],
		hasFloatingHeader = false,
		floatingHeader = [],
		backgroundColor = "default",
		basicConfig = {},
		stickyHeader = {},
	} = props;
	const {
		applyNowLink = {},
		headerLinks = [],
		backgroundColor: stickyHeaderBackgroundColor = {},
		buttonColor: stickyHeaderButtonColor = {},
		buttonTextColor: stickyHeaderButtonTextColor = {},
		linkColor: stickyHeaderLinkColor = {},
	} = stickyHeader || {};

	const [mobileNavigationOpen, openMobileNavigation] = useState(false);
	const [hamburgerSubmenu, sethamburgerSubmenu] = useState(null);

	const handleHamburgerSubmenuClick = (e, menuTitle) => {
		e.preventDefault();
		if (hamburgerSubmenu === menuTitle) {
			sethamburgerSubmenu(null);
			return;
		}
		sethamburgerSubmenu(menuTitle);
	};

	const toggleMobileNavigation = (open) => {
		openMobileNavigation(open);
		sethamburgerSubmenu(null);
	};

	const renderExpadableNavItem = (navigation) => {
		if (navigation.dropdownLinks && navigation.dropdownLinks.length) {
			return (
				<>
					<DropdownMenu showDropdown={hamburgerSubmenu === navigation.name}>
						{navigation.dropdownLinks.map(({ name, link }, index) => (
							<CtaLink
								Link={link.link}
								type={link.type}
								value={link.link}
								target={link.target}
								className="link"
								key={`${name}-${index}`}
							>
								{name}
							</CtaLink>
						))}
					</DropdownMenu>
				</>
			);
		} else {
			return null;
		}
	};

	return (
		<HeaderWrapper>
			{hasFloatingHeader ? (
				<SiteFloatingHeader>
					{floatingHeader.map((floatingHeaderContent, index) => {
						return (
							<FloatingHeaderContent key={index}>
								<CloudinaryImage
									className="desktopImage"
									url={floatingHeaderContent.icon}
									quality="auto:best"
									fetchFormat="auto"
									dpr="auto"
									lazyload={false}
								/>
								<FloatingHeaderText
									dangerouslySetInnerHTML={{
										__html: floatingHeaderContent.content,
									}}
								></FloatingHeaderText>
							</FloatingHeaderContent>
						);
					})}
				</SiteFloatingHeader>
			) : null}
			{headerLinks.length ? (
				<StickyHeaderWrapper
					backgroundColor={stickyHeaderBackgroundColor}
					linkColor={stickyHeaderLinkColor}
					buttonColor={stickyHeaderButtonColor}
					buttonTextColor={stickyHeaderButtonTextColor}
				>
					<nav>
						<ul className="navbar">
							{headerLinks.map(
								({
									link: { type, link, target } = {},
									label,
									component,
									phoneNumber,
								}) => (
									<li className="nav-item">
										{component === "headerLink" ? (
											<CtaLink
												type={type}
												value={link}
												target={target}
												className="sticky-header-link nav-link link"
											>
												{label}
											</CtaLink>
										) : (
											<AElement
												href={`tel:${phoneNumber}`}
												className="sticky-header-link nav-link link"
											>
												{label}
											</AElement>
										)}
									</li>
								),
							)}
							<li className="nav-item">
								<CtaLink
									type={applyNowLink?.type}
									value={applyNowLink?.link}
									className="sticky-header-link nav-link link apply-now"
								>
									{"Apply Now"}
								</CtaLink>
							</li>
						</ul>
					</nav>
				</StickyHeaderWrapper>
			) : null}
			<SiteHeader
				backgroundColor={backgroundColor}
				mobileHeight={logoConfig?.mobileHeight}
				mobileWidth={logoConfig?.mobileWidth}
			>
				<Logo
					className="logo-header"
					width={logoConfig?.width}
					height={logoConfig?.height}
				>
					{hasNavigations ? (
						<CtaLink
							Link={"/"}
							type={"external"}
							value={"/"}
							target={"_self"}
							className="header-logo"
							key={`logo-link`}
						>
							<CloudinaryImage
								className="siteLogo"
								url={
									logoConfig?.type === "primary"
										? basicConfig.primaryLogo
										: basicConfig.secondaryLogo
								}
								quality="auto:best"
								fetchFormat="auto"
								dpr="auto"
								lazyload={false}
								alt={logoConfig?.altText}
							/>
						</CtaLink>
					) : (
						<CloudinaryImage
							className="siteLogo"
							url={
								logoConfig?.type === "primary"
									? basicConfig.primaryLogo
									: basicConfig.secondaryLogo
							}
							quality="auto:best"
							fetchFormat="auto"
							dpr="auto"
							lazyload={false}
							alt={logoConfig?.altText}
						/>
					)}
				</Logo>
				{hasNavigations && navigations.length ? (
					<>
						{mobileNavigationOpen ? (
							<div
								className="mobileMenuIconWrapper"
								onClick={() => toggleMobileNavigation(false)}
							>
								<FontAwesomeIcon
									icon={faTimes}
									className={"hamburgerIcon"}
									size={"3x"}
									color={basicConfig.primaryColor}
								/>
							</div>
						) : (
							<div
								className="mobileMenuIconWrapper"
								onClick={() => toggleMobileNavigation(true)}
							>
								<FontAwesomeIcon
									icon={faBars}
									className={"hamburgerIcon"}
									size={"3x"}
									color={basicConfig.primaryColor}
								/>
							</div>
						)}
						<HeaderNavigationsWrapper
							mobileNavigationOpen={mobileNavigationOpen}
							isStickyHeader={headerLinks.length}
						>
							{navigations.map((navigation, index) => {
								return (
									<HeaderNavigation
										key={`${navigation.name}-${index}`}
										tabIndex={navigation.link ? index + 1 : 0}
									>
										{navigation.link ? (
											<CtaLink
												className="navLink"
												type={navigation?.link?.type}
												value={navigation?.link?.link}
												target={navigation?.link?.target}
											>
												{navigation.name}
											</CtaLink>
										) : (
											<>
												<DropdownLink
													onMouseEnter={(e) => {
														handleHamburgerSubmenuClick(e, navigation.name);
													}}
													onMouseLeave={(e) => {
														handleHamburgerSubmenuClick(e, null);
													}}
												>
													<span>{navigation.name}</span>
													{renderExpadableNavItem(navigation)}
												</DropdownLink>
												<DropdownLinkMobile>
													<span
														onClick={(e) => {
															handleHamburgerSubmenuClick(e, navigation.name);
														}}
													>
														{navigation.name}
														{navigation.name === hamburgerSubmenu ? (
															<FontAwesomeIcon
																icon={faMinus}
																className="submenuIcon"
															/>
														) : (
															<FontAwesomeIcon
																icon={faPlus}
																className="submenuIcon"
															/>
														)}
														{/* , faMinus */}
													</span>
													{renderExpadableNavItem(navigation)}
												</DropdownLinkMobile>
											</>
										)}
									</HeaderNavigation>
								);
							})}
							{hasFloatingHeader ? (
								<SiteFloatingHeaderMobile>
									{floatingHeader.map((floatingHeaderContent, index) => {
										return (
											<FloatingHeaderContent key={index}>
												<CloudinaryImage
													className="desktopImage"
													url={floatingHeaderContent.icon}
													quality="auto:best"
													fetchFormat="auto"
													dpr="auto"
													lazyload={false}
												/>
												<FloatingHeaderText
													dangerouslySetInnerHTML={{
														__html: floatingHeaderContent.content,
													}}
												></FloatingHeaderText>
											</FloatingHeaderContent>
										);
									})}
								</SiteFloatingHeaderMobile>
							) : null}
						</HeaderNavigationsWrapper>
					</>
				) : null}
			</SiteHeader>
		</HeaderWrapper>
	);
};

export default Header;
export const headerTransform = transform;
