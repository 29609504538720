import { getBluryAndLazyImageUrl } from "@website-builder/utilities-index";
import {
	getImageConfig,
	getLogoOrImageConfig,
} from "@website-builder/utilities-index";

export default (props, { basicConfig = null }) => {
	const {
		backgroundImage,
		header,
		subHeader,
		content,
		hasPartnerShip = false,
		partnerShipLogo = null,
		partnerShipText = "",
		mobileBackgroundImage,
		selectH1Header,
	} = props;
	let imageConfig = getImageConfig(backgroundImage, basicConfig);
	imageConfig = {
		...imageConfig,
		...getBluryAndLazyImageUrl(imageConfig.imageUrl),
	};
	let mobileImageConfig =
		mobileBackgroundImage?.image &&
		getBluryAndLazyImageUrl(mobileBackgroundImage?.image);

	return {
		header,
		subHeader,
		content,
		imageConfig,
		mobileImageConfig,
		partnerShipLogo:
			partnerShipLogo && getLogoOrImageConfig(partnerShipLogo, basicConfig),
		partnerShipText,
		hasPartnerShip,
		selectH1Header,
	};
};
