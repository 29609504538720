import styled from "styled-components";
import {
	DESKTOP_SM_BP,
	FONT_WT_LIGHT,
	COLOR_TEXT_PRIMARY_B2U,
	COLOR_WHITE,
	COLOR_LIGHTEST_GREY,
	COLOR_NOBEL_GREY,
	MOBILE_L_BP,
} from "@website-builder/utilities-index";
import { getColor } from "@website-builder/utilities-index";

export const HeaderWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const StickyHeaderWrapper = styled.div`
	display: none;
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		.navbar {
			display: flex;
			margin: 0;
			padding: 0;
			position: fixed;
			width: 100%;
			& .nav-item {
				background-color: ${({ backgroundColor }) => backgroundColor};
				text-align: center;
				& .nav-link {
					width: 100%;
					line-height: 60px;
					text-align: center;
					font-size: 14px;
					color: ${({ linkColor }) => linkColor};
					font-weight: 500;
					cursor: pointer;
					text-decoration: underline;
				}
			}
			& .nav-item {
				flex-grow: 1;
				list-style: none;
				&:last-child {
					background-color: ${({ buttonColor }) => buttonColor};
					& .nav-link {
						color: ${({ buttonTextColor }) => buttonTextColor};
						text-decoration: none;
						font-size: 18px;
						font-weight: 700;
						border-width: 0px;
					}
				}
			}
		}
		left: 0px;
		top: 0px;
		position: relative;
		display: block;
		height: 60px;
		z-index: 999;
	}
`;

export const SiteFloatingHeader = styled.div`
	width: 100%;
	height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding: 0px 5px;
	background-color: #f9f9f9;
	@media only screen and (max-width: ${DESKTOP_SM_BP - 1}px) {
		display: none;
	}
`;

export const SiteFloatingHeaderMobile = styled.div`
	display: none;
	@media only screen and (max-width: ${DESKTOP_SM_BP - 1}px) {
		display: flex;
		flex-wrap: wrap;
		padding: 5px 20px 25px;
		flex-direction: column;
	}
`;

export const FloatingHeaderContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	img {
		&.desktopImage {
			height: 18px;
			margin-right: 5px;
		}
	}
	@media only screen and (max-width: ${DESKTOP_SM_BP - 1}px) {
		margin-top: 10px;
	}
`;

export const FloatingHeaderText = styled.p`
	margin: 0 20px 0 0;
	font-size: 15px;
	font-weight: ${FONT_WT_LIGHT};
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: ${COLOR_TEXT_PRIMARY_B2U};
	p {
		margin: 0px;
	}
	a {
		color: ${COLOR_TEXT_PRIMARY_B2U};
		text-decoration: none;
	}
`;

export const SiteHeader = styled.div`
	width: 100%;
	height: 90px;
	display: flex;
	align-items: center;
	flex-direction: row;
	padding: 15px 30px 0px 30px;
	justify-content: space-between;
	${(props) =>
		props.backgroundColor.type && props.backgroundColor.type === "custom"
			? `background-color: ${props.backgroundColor.color};`
			: props.backgroundColor.type
				? `background-color: ${getColor(props.backgroundColor.type)};`
				: `background-color: ${getColor(
						props.backgroundColor,
					)};`} //For Backward Compatibility
  .mobileMenuIconWrapper {
		display: none;
	}
	@media only screen and (max-width: ${DESKTOP_SM_BP - 1}px) {
		height: 90px;
		padding: 25px 30px 0px 15px;
		.mobileMenuIconWrapper {
			display: flex;
			margin-left: auto;
			cursor: pointer;
		}
	}
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		.header-logo img {
			margin-right: 10px;
		}
		.logo-header img {
			height: ${({ mobileHeight }) => `${mobileHeight}px`};
			width: ${({ mobileWidth }) => `${mobileWidth}px`};
		}
	}
`;

export const HeaderNavigationsWrapper = styled.ul`
	display: flex;
	flex-direction: row;
	list-style-type: none;
	margin-top: 30px;
	left: 0;
	top: 62px;
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		top: ${({ isStickyHeader }) => (isStickyHeader ? "122px" : "62px")};
	}
	@media only screen and (max-width: ${DESKTOP_SM_BP - 1}px) {
		display: ${(props) => (props.mobileNavigationOpen ? "flex" : "none")};
		position: absolute;
		flex-direction: column;
		background-color: ${COLOR_LIGHTEST_GREY};
		padding: 0px;
		width: 100%;
		z-index: 100;
	}
`;

export const HeaderNavigation = styled.li`
  &:last-child {
    margin-right: 0;
  }

  .navLink {
    text-decoration: none;
    font-size: 18px;
    text-align: center;
    color: ${COLOR_TEXT_PRIMARY_B2U};
    margin-right: 20px;
    min-width: 85px;
    height: 100%;
    cursor: pointer;
    & > span,
    & > a {
      color: ${COLOR_TEXT_PRIMARY_B2U};
      text-decoration: none;
    }
    &:hover {
      text-shadow: 0 0 .65px #333, 0 0 .65px #333;
      cursor: pointer;
    }
  }

  @media only screen and (min-width: ${DESKTOP_SM_BP}px) {
    height: 50px;
    margin-top: 20px;
  }

  @media only screen and (max-width: ${DESKTOP_SM_BP - 1}px) {
    border-bottom: 1px solid ${COLOR_NOBEL_GREY};
    padding: 15px 0px;
    &:last-of-type {
      border-bottom: none;
    }
    & > a {
      margin-left: 20px;
    }
    .navLink {
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: 700;
        margin-right: 0px;
        font-size: 16px;
        & > span {
          margin-left: 20px;
        }
        &:hover {
          text-shadow: none;
          cursor: auto;
        }
      }
    }
  }
`;

export const DropdownLink = styled.div`
	position: relative;
	font-size: 18px;
	text-align: center;
	color: ${COLOR_TEXT_PRIMARY_B2U};
	margin-right: 20px;
	min-width: 85px;
	height: 100%;
	cursor: pointer;
	& > span,
	& > a {
		color: ${COLOR_TEXT_PRIMARY_B2U};
		text-decoration: none;
	}
	&:hover {
		text-shadow:
			0 0 0.65px #333,
			0 0 0.65px #333;
		cursor: pointer;
	}
	@media only screen and (max-width: ${DESKTOP_SM_BP - 1}px) {
		display: none;
		font-size: 16px;
	}
`;

export const DropdownLinkMobile = styled(DropdownLink)`
	display: none;
	@media only screen and (max-width: ${DESKTOP_SM_BP - 1}px) {
		text-align: left;
		display: flex;
		flex-direction: column;
		justify-content: center;
		font-weight: 700;
		margin-right: 0px;
		& > span {
			margin-left: 20px;
			display: flex;
			justify-content: space-between;
			margin-right: 15px;
			.submenuIcon {
				width: 16px !important;
				height: 16px !important;
				color: ${COLOR_TEXT_PRIMARY_B2U};
			}
		}
		&:hover {
			text-shadow: none;
			cursor: auto;
		}
	}
`;

export const DropdownMenu = styled.div`
	display: ${(props) => (props.showDropdown ? "flex" : "none")};
	flex-direction: column;
	z-index: 10;
	position: absolute;
	max-width: 700px;
	background: ${COLOR_WHITE};
	animation-timing-function: ease-in-out;
	// transform: translateX(-50%);
	// left: 50%;
	top: 25px;
	white-space: nowrap;
	padding: 11px 5px 13px;
	border: solid 1px #d8d8d8;

	a {
		white-space: nowrap;
		color: ${COLOR_TEXT_PRIMARY_B2U};
		text-decoration: none;
		line-height: 1;
		padding: 10px 0;
		display: block;
		text-align: left;
		text-shadow: none;
		font-weight: normal;
		&:hover {
			text-shadow:
				0 0 0.65px #333,
				0 0 0.65px #333;
			cursor: pointer;
		}
	}

	@media only screen and (max-width: ${DESKTOP_SM_BP - 1}px) {
		display: ${(props) => (props.showDropdown ? "flex" : "none")};
		border: none;
		flex-direction: column;
		border-top: 1px solid ${COLOR_NOBEL_GREY};
		padding: 10px 0px 0px 20px;
		max-width: unset;
		margin-top: 20px;
		background: transparent;
		position: unset;
		top: unset;
		& > a {
			text-shadow: none;
		}
	}
`;
