import { MOBILE_L_BP } from "@website-builder/utilities-index";
import styled from "styled-components";

export const NavigationFooterPaidContainer = styled.footer`
	background-color: var(--theme-primary);
	width: 100%;
	padding: 80px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	& .copyright {
		color: var(--primary-neutral-white);
	}
	img {
		width: 100%;
		height: auto;
		max-width: ${({ logoWidth }) => (logoWidth ? `${logoWidth}px` : "250px")};
	}

	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		padding: 32px 24px 46px;
	}

	.university-address {
		text-align: center;
	}
`;
