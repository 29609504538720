import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { StyledFormContainer } from "./styles";
import { Grid, Col, HubspotForm } from "@website-builder/ui/shared/elements";
import { formatColor } from "@website-builder/utilities/utils/DesignUtils.js";

const FormContainer = (props) => {
	const {
		formDetails,
		pageSection = "",
		context = {},
		formData = [],
		hasDarkBackground,
		topBackgroundColor,
		bottomBackgroundColor,
		sectionName,
		itemsOnFirstColumn,
		occupyFullWidth = false,
		matchStyleToIframeForm = false,
	} = props;
	const [componentWidth, setComponentWidth] = useState(null);
	const componentRef = useRef(null);
	const isTwoColumnSectionContent = sectionName === "TwoColumnSection";

	useEffect(() => {
		// Function to update the component width
		const updateComponentWidth = () => {
			if (componentRef.current) {
				const width = componentRef.current.offsetWidth;
				setComponentWidth(width);
			}
		};
		if (isTwoColumnSectionContent) {
			// Initial measurement when the component mounts
			updateComponentWidth();

			// Attach the event listener for window resize
			window.addEventListener("resize", updateComponentWidth);
		}

		// Cleanup the event listener on component unmount
		return () => {
			if (isTwoColumnSectionContent) {
				window.removeEventListener("resize", updateComponentWidth);
			}
		};
	}, []);
	let formColStart = 3;
	let formColSpan = 8;
	if (!!itemsOnFirstColumn || occupyFullWidth) {
		formColStart = 0;
		formColSpan = 12;
	}
	let gridHMargin = null;
	if (occupyFullWidth) {
		gridHMargin = [0, 0];
	}
	const hubspotFormJSX = (
		<HubspotForm
			isMobileView={isTwoColumnSectionContent && componentWidth <= 900}
			formDetails={formDetails}
			context={context}
			pageSection={pageSection}
			formData={formData}
			itemsOnFirstColumn={itemsOnFirstColumn || null}
			darkBg={hasDarkBackground}
			matchStyleToIframeForm={matchStyleToIframeForm}
		/>
	);
	if (matchStyleToIframeForm) {
		return hubspotFormJSX;
	}
	return (
		<StyledFormContainer
			className={clsx("form-container-component", "outer-margin-wrap", {
				"up-lead-form": formDetails?.destination?.some?.(
					(ele) => ele === "active-campaign",
				),
			})}
			topBackgroundColor={formatColor(topBackgroundColor)}
			bottomBackgroundColor={formatColor(bottomBackgroundColor)}
			maxWidth={formDetails?.styleConfiguration?.[0]?.formWidth || "1000px"}
			ref={componentRef}
			matchStyleToIframeForm={matchStyleToIframeForm}
		>
			<Grid vAlign="center" hMargin={gridHMargin}>
				<Col
					start={formColStart}
					span={formColSpan}
					mdSpan={12}
					hAlign="center"
				>
					{hubspotFormJSX}
				</Col>
			</Grid>
		</StyledFormContainer>
	);
};

export default FormContainer;
