import React from "react";

import CustomFooter from "./CustomFooter";
import {
	DefaultFooter,
	UniversityContentWrapper,
	UniversityAddress,
	UniversityContent,
	UniversityContentMobile,
	CopyrightContentWrapper,
	CopyrightContent,
	NavigationsWrapper,
	NavigationColumnMobileWrapper,
	NavigationColumn,
	NavigationColumnMobile,
	NavigationBlock,
	NavigationTitle,
	NavigationWrapper,
	DisclamerSection,
} from "./styles";
import { Logo } from "./../../styles";

import { CtaLink } from "../../Elements";
import { CloudinaryImage } from "@website-builder/ui/shared/elements";

import { transform } from "./transform";

const Footer = (props) => {
	const {
		logoConfig,
		hasNavigations = false,
		navigationColumns = [],
		backgroundColor = "default",
		fontColor,
		address = "",
		copyrightYear = "",
		copyrightText = "Powered by Springboard",
		basicConfig = {},
		hasCustomFooter,
		customFooter,
		hasDefaultFooter,
		disclaimerSection,
		disclaimerColor,
	} = props;

	const renderNavigationBlock = (navigationBlocks) => {
		return navigationBlocks.map((navigationBlock, index) => {
			return (
				<NavigationBlock key={index} fontColor={fontColor}>
					{navigationBlock.link ? (
						<CtaLink
							className="navigationBlockLink"
							type={navigationBlock?.link?.type}
							value={navigationBlock?.link?.link}
						>
							{navigationBlock.title}
						</CtaLink>
					) : (
						<>
							<NavigationTitle key={`nt-${index}`}>
								{navigationBlock.title}
							</NavigationTitle>
							<NavigationWrapper key={`nw-${index}`} fontColor={fontColor}>
								{navigationBlock.navigations.map(({ name, link }, index) => {
									return (
										<CtaLink
											Link={link.link}
											type={link.type}
											value={link.link}
											target={link.target}
											className="navigationLink"
											key={`${name}-${index}`}
										>
											{name}
										</CtaLink>
									);
								})}
							</NavigationWrapper>
						</>
					)}
				</NavigationBlock>
			);
		});
	};

	const renderNavigationColumn = (navigationColumns) => {
		return navigationColumns.map(({ navigationBlocks = [] }, index) => {
			return (
				<NavigationColumn key={index}>
					{renderNavigationBlock(navigationBlocks)}
				</NavigationColumn>
			);
		});
	};

	const renderNavigationColumnMobile = (navigationColumns) => {
		let footerRows = [];
		let footerRowCount =
			Math.floor(navigationColumns.length / 2) + (navigationColumns.length % 2);
		let j = 0;
		for (let i = 0; i < footerRowCount; i++) {
			const navigationColumn1 = navigationColumns[j];
			const navigationColumn2 = navigationColumns[++j];
			if (navigationColumn1 && navigationColumn2) {
				footerRows.push(
					<NavigationColumnMobileWrapper key={i}>
						<NavigationColumnMobile>
							{renderNavigationBlock(navigationColumn1.navigationBlocks)}
						</NavigationColumnMobile>
						<NavigationColumnMobile>
							{renderNavigationBlock(navigationColumn2.navigationBlocks)}
						</NavigationColumnMobile>
					</NavigationColumnMobileWrapper>,
				);
			} else if (navigationColumn1) {
				footerRows.push(
					<NavigationColumnMobileWrapper key={i}>
						<NavigationColumnMobile>
							{renderNavigationBlock(navigationColumn1.navigationBlocks)}
						</NavigationColumnMobile>
					</NavigationColumnMobileWrapper>,
				);
			}
			j++;
		}

		return footerRows;
	};

	const renderUniversityContent = (
		basicConfig,
		address,
		copyrightYear,
		copyrightText,
	) => {
		return (
			<>
				<UniversityAddress
					hasNavigations={hasNavigations}
					dangerouslySetInnerHTML={{ __html: address }}
				/>
				<CopyrightContentWrapper>
					<CopyrightContent
						hasNavigations={hasNavigations}
						fontColor={fontColor}
					>
						Copyright © {copyrightYear}
					</CopyrightContent>
					<CopyrightContent
						hasNavigations={hasNavigations}
						fontColor={fontColor}
					>
						{copyrightText}
					</CopyrightContent>
				</CopyrightContentWrapper>
			</>
		);
	};

	return (
		<footer>
			{hasDefaultFooter && (
				<DefaultFooter
					hasNavigations={hasNavigations}
					backgroundColor={backgroundColor}
					fontColor={fontColor}
				>
					<UniversityContentWrapper
						hasNavigations={hasNavigations}
						fontcolor={fontColor}
					>
						<Logo width={logoConfig.width} height={logoConfig.height}>
							<CloudinaryImage
								className="footerLogo"
								url={
									logoConfig.type === "primary"
										? basicConfig.primaryLogo
										: basicConfig.secondaryLogo
								}
								quality="auto:best"
								fetchFormat="auto"
								dpr="auto"
								alt={logoConfig?.altText}
							/>
						</Logo>
						<UniversityContent hasNavigations={hasNavigations}>
							{renderUniversityContent(
								basicConfig,
								address,
								copyrightYear,
								copyrightText,
							)}
						</UniversityContent>
					</UniversityContentWrapper>
					{hasNavigations ? (
						<NavigationsWrapper>
							{renderNavigationColumn(navigationColumns)}
							{renderNavigationColumnMobile(navigationColumns)}
						</NavigationsWrapper>
					) : null}
					<UniversityContentMobile hasNavigations={hasNavigations}>
						{renderUniversityContent(
							basicConfig,
							address,
							copyrightYear,
							copyrightText,
						)}
					</UniversityContentMobile>
				</DefaultFooter>
			)}
			{hasCustomFooter && (
				<CustomFooter
					{...customFooter}
					basicConfig={basicConfig}
				></CustomFooter>
			)}
			{disclaimerSection.length !== 0 && (
				<DisclamerSection
					disclaimerFontColor={
						disclaimerColor === "defaultFooterColor"
							? fontColor
							: disclaimerColor === "customFooterColor" &&
								customFooter?.fontColor
					}
					disclaimerBackColor={
						disclaimerColor === "defaultFooterColor"
							? backgroundColor
							: disclaimerColor === "customFooterColor" &&
								customFooter?.backgroundColor
					}
				>
					{disclaimerSection}
				</DisclamerSection>
			)}
		</footer>
	);
};

export default Footer;
export const footerTransform = transform;
