import styled from "styled-components";
import {
	DESKTOP_M_BP,
	DESKTOP_SM_BP,
	TABLET_BP,
	FONT_WT_LIGHT,
	COLOR_WHITE,
	FONT_WT_REGULAR,
	MOBILE_L_BP,
} from "@website-builder/utilities-index";
import { getColor } from "@website-builder/utilities-index";

export const DefaultFooter = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	${
		(props) =>
			props.backgroundColor.type && props.backgroundColor.type === "custom"
				? `background-color: ${props.backgroundColor.color};`
				: props.backgroundColor.type
					? `background-color: ${getColor(props.backgroundColor.type)};`
					: `background-color: ${getColor(props.backgroundColor)};` //For Backward Compatibility
	}
	color:${({ fontColor }) => fontColor};
	padding: ${(props) =>
		props.hasNavigations ? "55px 60px 95px" : "55px 0px 95px"};
	justify-content: ${(props) => (props.hasNavigations ? "unset" : "center")};
	@media only screen and (max-width: ${DESKTOP_M_BP - 1}px) {
		padding: ${(props) =>
			props.hasNavigations ? "55px 40px 75px" : "55px 0px 75px"};
	}
	@media only screen and (max-width: ${DESKTOP_SM_BP - 1}px) {
		flex-direction: column;
		align-items: ${(props) => (props.hasNavigations ? "unset" : "center")};
		padding: ${(props) =>
			props.hasNavigations ? "55px 20px 75px 20px" : "55px 0px 90px"};
	}
`;

export const UniversityContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: ${(props) => (props.hasNavigations ? "400px" : "unset")};
	margin-right: ${(props) => (props.hasNavigations ? "148px" : "0px")};
	align-items: ${(props) => (props.hasNavigations ? "unset" : "center")};

	@media only screen and (max-width: ${DESKTOP_M_BP - 1}px) {
		margin-right: ${(props) => (props.hasNavigations ? "50px" : "0px")};
	}
	@media only screen and (max-width: ${DESKTOP_SM_BP - 1}px) {
		margin-right: 0px;
		margin-bottom: 15px;
	}
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		margin-right: 0px;
		margin-bottom: 15px;
	}
`;

export const UniversityContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: ${(props) => (props.hasNavigations ? "unset" : "center")};
	@media only screen and (max-width: ${DESKTOP_SM_BP - 1}px) {
		display: none;
	}
`;

export const UniversityContentMobile = styled.div`
	display: none;
	flex-direction: column;
	align-items: ${(props) => (props.hasNavigations ? "unset" : "center")};
	@media only screen and (max-width: ${DESKTOP_SM_BP - 1}px) {
		display: flex;
	}
`;

export const UniversityAddress = styled.div`
	margin-top: ${(props) => (props.hasNavigations ? "40px" : "25px")};
	max-width: 242px;
	p {
		font-size: 16px;
		color: ${({ fontColor }) => fontColor};
		white-space: break-spaces;
		text-align: ${(props) => (props.hasNavigations ? "left" : "center")};
	}
	@media only screen and (max-width: ${DESKTOP_SM_BP - 1}px) {
		margin-top: 0px;
	}
`;

export const CopyrightContentWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

export const CopyrightContent = styled.p`
	margin-top: ${(props) => (props.hasNavigations ? "40px" : "25px")};
	font-size: 16px;
	color: ${({ fontColor }) => fontColor};
	text-align: left;
	&:last-of-type {
		margin-left: 10px;
	}
	@media only screen and (max-width: ${DESKTOP_SM_BP - 1}px) {
		margin-top: 10px;
	}
`;

export const NavigationsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 25px;
	@media only screen and (max-width: ${TABLET_BP - 1}px) {
		flex-direction: column;
	}
`;

export const NavigationColumnMobileWrapper = styled.span`
	display: none;
	flex-direction: row;
	margin-top: 20px;
	@media only screen and (max-width: ${TABLET_BP - 1}px) {
		display: flex;
	}
`;

export const NavigationColumn = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 60px;
	&:last-of-type {
		margin-right: 0px;
	}
	@media only screen and (max-width: ${DESKTOP_M_BP - 1}px) {
		margin-right: 30px;
	}
	@media only screen and (max-width: ${TABLET_BP - 1}px) {
		display: none;
	}
`;

export const NavigationColumnMobile = styled.div`
	display: none;
	flex-direction: column;
	width: 155px;
	margin-right: 25px;
	&:last-of-type {
		margin-right: 0px;
	}
	@media only screen and (max-width: ${TABLET_BP - 1}px) {
		display: flex;
	}
`;

export const NavigationBlock = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	.navigationBlockLink {
		text-decoration: none;
		font-size: 18px;
		text-align: left;
		font-weight: ${FONT_WT_REGULAR};
		color: ${({ fontColor }) => fontColor};
		cursor: pointer;
		& > span,
		& > a {
			color: ${({ fontColor }) => fontColor};
			text-decoration: none;
		}
		&:hover {
			text-shadow:
				0 0 0.65px #333,
				0 0 0.65px #333;
			cursor: pointer;
		}
	}

	@media only screen and (max-width: ${DESKTOP_SM_BP - 1}px) {
		margin-right: 0px;
	}
`;

export const NavigationTitle = styled.p`
	font-size: 18px;
	text-align: left;
	color: ${({ fontColor }) => fontColor};
	color: ${({ fontColor }) => fontColor};
	margin: 0px;
	font-weight: ${FONT_WT_REGULAR};
`;

export const NavigationWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	.navigationLink {
		text-decoration: none;
		font-size: 16px;
		text-align: left;
		color: ${({ fontColor }) => fontColor};
		cursor: pointer;
		margin-bottom: 15px;
		font-weight: ${FONT_WT_LIGHT};
		& > span,
		& > a {
			color: ${({ fontColor }) => fontColor};
			text-decoration: none;
		}
		&:hover {
			text-shadow:
				0 0 0.65px #333,
				0 0 0.65px #333;
			cursor: pointer;
		}
	}
`;

export const DisclamerSection = styled.div`
	padding: 40px 65px;
	color: ${(props) =>
		props?.disclaimerFontColor ? props?.disclaimerFontColor : "#ffffff"};
	${(props) =>
		props?.disclaimerBackColor?.type &&
		props?.disclaimerBackColor?.type === "custom"
			? `background-color: ${props?.disclaimerBackColor?.color};`
			: props?.disclaimerBackColor?.type
				? `background-color: ${getColor(props?.disclaimerBackColor?.type)};`
				: `background-color: ${props?.disclaimerBackColor};`}

	font-size: 14px;
	@media only screen and (max-width: ${DESKTOP_M_BP}px) {
		padding: 40px 46px;
	}

	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		padding: 40px 28px;
	}
`;
