import { HS_API, OBJECT_TYPE_ID, ZAPIER_WEB_HOOKS } from "./constants";
import {
	prepareAppStartedPayload,
	prepareInterestedApiPayload,
	prepareLeadParamFields,
} from "./utils";
import { getCSRFTokenFromCookie } from "@website-builder/utilities/utils/utils.js";

export const sendToHubspot = async (
	apiBaseUrlString,
	context,
	formID,
	formValues,
	includeLeadParams,
) => {
	const fields = [];
	Object.entries(formValues).forEach(([key, value]) => {
		const fieldValue = value;
		const fieldValueObj = {
			objectTypeId: OBJECT_TYPE_ID,
			name: key,
			value: fieldValue === undefined || fieldValue === null ? "" : fieldValue,
		};
		fields.push(fieldValueObj);
	});

	if (includeLeadParams) {
		const leadParams = prepareLeadParamFields(context);
		fields.push(...leadParams);
	}
	const hsSubmitFormAPI = `${apiBaseUrlString}/${HS_API.POST_FORM_DATA}`;
	const data = {
		fields,
		context: {
			pageUri: window?.location?.href,
			pageName: document?.title || context?.slug || context?.context?.name,
		},
	};
	const csrfTokenValue = getCSRFTokenFromCookie();
	const requestOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"x-csrftoken": csrfTokenValue,
		},
		body: JSON.stringify({
			formId: formID,
			data: data,
		}),
	};
	try {
		const res = await fetch(hsSubmitFormAPI, requestOptions);
		const response = await res.json();
		if (!response.hasOwnProperty("inlineMessage")) {
			throw new Error(`Error submitting form to Hubspot`);
		}
	} catch (err) {
		console.error(`⛔️ Hubspot API Error [formID: ${formID}]:`, err);
		throw err;
	}
};

/**
 * Makes a POST request to the Hubspot form Interested API.
 * This API is used to send applied lead to marketo and storing data in the database.
 *
 * @param {string} apiBaseUrl - The base URL for the API.
 * @param {Object} context - The context object.
 * @param {string} formID - The ID of the form.
 * @param {Object} formValues - The values submitted bt user on the form.
 *
 * @returns {Promise<void>} Returns a promise that resolves when the API call is successful.
 *
 * @throws Will throw an error if the API call was not successful.
 */
export const callInterestedAPI = async (
	apiBaseUrl,
	context,
	formID,
	formValues,
) => {
	const url = `${apiBaseUrl}/${HS_API.LEAD_API}`;
	const payload = prepareInterestedApiPayload(formValues, context);
	const csrfTokenValue = getCSRFTokenFromCookie();
	try {
		const res = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"x-csrftoken": csrfTokenValue,
			},
			body: JSON.stringify(payload),
			referrerPolicy: "no-referrer-when-downgrade",
			credentials: "include",
		});
		const response = await res.json();
		const { result = "", success = false } = response;
		if (result !== "success") {
			throw new Error(`Error submitting lead`);
		}
	} catch (err) {
		console.error(`⛔️ Lead API Error [formID: ${formID}]:`, err);
		throw err;
	}
};

/**
 * Makes a POST request to the App Started Interested API.
 * This API is used to send app_started lead to marketo and storing data in the database.
 *
 * @param {string} apiBaseUrl - The base URL for the API.
 * @param {Object} context - The context object.
 * @param {string} formID - The ID of the form.
 * @param {Object} formValues - The values submitted bt user on the form.
 *
 *
 * @returns {Promise<void>} Returns a promise that resolves when the API call is successful.
 *
 * @throws Will throw an error if the API call failed.
 */
export const callAppStartedAPI = async (
	apiBaseUrl,
	context,
	formID,
	formValues,
) => {
	const url = `${apiBaseUrl}/${HS_API.APP_STARTED_API}`;
	const payload = prepareAppStartedPayload(formValues?.email, context);
	const csrfTokenValue = getCSRFTokenFromCookie();
	try {
		const res = await fetch(url, {
			method: "POST",
			headers: {
				"x-csrftoken": csrfTokenValue,
			},
			body: payload,
			referrerPolicy: "no-referrer-when-downgrade",
			credentials: "include",
		});
		const response = await res.json();
		const { result = "", success = false } = response;
		if (result !== "success") {
			throw new Error(`Error submitting lead`);
		}
	} catch (err) {
		console.error(`⛔️ App started API Error [formID: ${formID}]:`, err);
		throw err;
	}
};

export const triggerZapier = (
	formValues = {},
	context = {},
	isProductionBuild = false,
	submitTriggers = {},
) => {
	const {
		triggersSlack = false,
		slackChannel = "",
		triggersEmail = false,
		emailRecipients = "",
	} = submitTriggers;
	const data = {
		...formValues,
		"Page URL": `${context?.globalHostName}/${context?.full_slug}`,
		"Page Name": document?.title || context?.slug,
	};
	const requestOptions = {
		method: "POST",
		body: "",
	};
	if (isProductionBuild && triggersSlack) {
		requestOptions.body = JSON.stringify({ ...data, recipient: slackChannel });
		fetch(ZAPIER_WEB_HOOKS.SLACK, requestOptions)
			.then((res) => res.json())
			.then((response) => {
				console.log("Zapier slack alert success");
			})
			.catch((err) => {
				console.error("Zapier slack error => ", err);
			});
	}
	if (isProductionBuild && triggersEmail) {
		requestOptions.body = JSON.stringify({
			...data,
			recipient: emailRecipients,
		});
		fetch(ZAPIER_WEB_HOOKS.EMAIL, requestOptions)
			.then((res) => res.json())
			.then((response) => {
				console.log("Zapier email notify success");
			})
			.catch((err) => {
				console.error("Zapier email error => ", err);
			});
	}
};
