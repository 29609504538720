import { COLOR_WHITE } from "@website-builder/utilities-index";

export default ({
	backgroundColor = {},
	fontColor = {},
	backgroundImage = {},
	hashlink = "",
	columns = [],
	mobileBackgroundImage = {},
}) => {
	return {
		backgroundColor: backgroundColor.color || COLOR_WHITE,
		fontColor: fontColor.color || COLOR_WHITE,
		backgroudImageConfig: backgroundImage.image ? backgroundImage : null,
		mobileBackgroundImageConfig: mobileBackgroundImage.image
			? mobileBackgroundImage
			: null,
		hashlink,
		columns,
	};
};
