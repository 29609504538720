import { TABLET_M_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import styled from "styled-components";

export const MenuWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
	padding-top: 32px;
	padding-right: 24px;
	padding-bottom: 40px;
	padding-left: 24px;

	@media only screen and (max-width: ${TABLET_M_BP}px) {
		padding: 0px;
	}

	.course-menu-separator {
		opacity: unset;
	}
`;

export const CourseCategory = styled.div`
	display: flex;
	gap: 8px;
	align-items: flex-start;
	margin-bottom: 10px;
	padding: 8px 10px;

	@media only screen and (max-width: ${TABLET_M_BP}px) {
		margin-bottom: 20px;
		padding: 0px;
	}
`;

export const CourseLinks = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const CourseLink = styled.div`
	display: flex;
	gap: 10px;
	width: fit-content;
	align-items: center;

	@media (min-width: 991px) and (max-width: 1250px) {
		flex-direction: column;
		gap: 4px;
		margin-bottom: 5px !important;
	}
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		gap: 6px;
		align-items: flex-start;
	}
	&:hover {
		p {
			font-weight: 600;
		}
	}
`;

export const CourseInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 8px 10px;
	padding-left: 16px;
`;
