//Accordion is used inside Tuition component
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import {
	Header,
	DropdownItem,
	DropdownContent,
	AccordionWrapper,
	SubHeading,
} from "./styles";
import { RichText } from "./../../styles/richText";

const Accordion = ({
	headerName,
	accordionItems,
	headerAlignment,
	headerSize,
	subHeading,
	activeStateColor,
	inactiveStateColor,
}) => {
	const renderExpadableItem = (item) => {
		if (item.itemName && item.contentInHtml) {
			return (
				<DropdownContent showDropdown={expanded === item._uid}>
					<RichText>
						<div
							className="rich-text-wrapper"
							dangerouslySetInnerHTML={{ __html: item.contentInHtml }}
						></div>
					</RichText>
				</DropdownContent>
			);
		} else {
			return null;
		}
	};

	const [expanded, setExpanded] = useState(null);

	const handleHamburgerMenuClick = (id) => {
		if (id === expanded) {
			setExpanded(null);
		} else {
			setExpanded(id);
		}
	};

	return (
		<AccordionWrapper>
			{headerName && (
				<Header align={headerAlignment} headerSize={headerSize}>
					{headerName}
				</Header>
			)}
			{subHeading && (
				<SubHeading align={headerAlignment}>{subHeading}</SubHeading>
			)}

			{accordionItems.map((item, index) => {
				item.expanded = false;
				return (
					<section key={`s-${index}`}>
						<DropdownItem
							onClick={() => {
								handleHamburgerMenuClick(item._uid);
							}}
							activeIem={expanded === item._uid}
							activeStateColor={activeStateColor}
							inactiveStateColor={inactiveStateColor}
						>
							{item.itemName}
							{item._uid === expanded ? (
								<FontAwesomeIcon icon={faMinus} className="submenuIcon" />
							) : (
								<FontAwesomeIcon icon={faPlus} className="submenuIcon" />
							)}
						</DropdownItem>
						{renderExpadableItem(item)}
					</section>
				);
			})}
		</AccordionWrapper>
	);
};

export default Accordion;
