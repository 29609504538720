import styled from "styled-components";

export const Line = styled.figure`
	padding: 0;
	margin: 0;
	line-height: 0;

	position: absolute;
	${(props) => (props.top ? `top: ${props.top};` : "")}
	${(props) => (props.right ? `right: ${props.right};` : "")}
  ${(props) => (props.bottom ? `bottom: ${props.bottom};` : "")}
  ${(props) => (props.left ? `left: ${props.left};` : "")}
  transform: ${(props) =>
		props.rotate ? `rotate(${props.rotate}deg)` : ""} ${(props) =>
		props.flip ? "scale(-1,1)" : ""} ${(props) =>
		props.x ? `translateX(${props.x})` : ""}  ${(props) =>
		props.y ? `translateY(${props.y})` : ""};

	& > svg {
		height: min-content;
		${(props) => (props.size ? `width: ${props.size};` : "")}
	}

	& > svg > path {
		${(props) => (props.stroke ? `stroke: ${props.stroke};` : "")}
		${(props) => (props.opacity ? `opacity: ${props.opacity};` : "")}
    ${(props) => (props.thickness ? `stroke-width: ${props.thickness};` : "")}
	}
`;
