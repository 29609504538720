import React from "react";
import { NavigationFooterPaidContainer } from "./style";
import { richTextResolver } from "@website-builder/utilities-index";
import { Link, Text } from "@website-builder/ui/shared/elements";

const NavigationFooterPaid = ({ logo, address, basicConfig, className }) => {
	const getLogo = () => {
		if (logo?.type === "primary") {
			return basicConfig?.primaryLogo;
		} else if (logo?.type === "secondary") {
			return basicConfig?.secondaryLogo;
		} else if (logo.type === "custom") {
			return logo?.image;
		}
		return null;
	};

	return (
		<NavigationFooterPaidContainer
			logoWidth={logo?.width}
			className={`navigation-footer navigation-footer-paid ${className}`}
		>
			<h2 className="sb-sr-only">Page Footer</h2>
			<Link href="/">
				<img
					src={getLogo()}
					alt={logo?.altTag || "University Logo"}
					width={logo?.width || "250px"}
					height={logo?.height || "40px"}
				/>
			</Link>
			<Text
				variant="paragraph_S"
				className="university-address"
				color={{ base: "primary", color: "neutral", shade: "white" }}
			>
				{address}
			</Text>
			<Text
				variant="paragraph_S"
				color={{ base: "primary", color: "neutral", shade: "white" }}
			>
				<p className="copyright">
					Copyright &copy; {new Date().getFullYear()} Springboard
				</p>
			</Text>
		</NavigationFooterPaidContainer>
	);
};

export default NavigationFooterPaid;
