import React, { forwardRef, useEffect, useState } from "react";
import {
	Text,
	Badge,
	Icon,
	Separator,
	Link,
	Button,
} from "@website-builder/ui/shared/elements";
import {
	CourseActionItem,
	CourseActions,
	CourseMenuItem,
	CourseMenuItemWrapper,
	CourseMenuWrapper,
	CourseMenuFooter,
	LeftSection,
	RightSection,
	AnimatedDiv,
	CourseActionsMobile,
	CourseActionsHeading,
	Courses,
} from "./style";
import {
	isSafariBrowser,
	addToDataLayer,
} from "@website-builder/utilities/utils/utils.js";
import { isBusinessVerticalB2U } from "@website-builder/utilities/utils/helpers.js";
import { handleCTAClick } from "@website-builder/utilities/utils/DesignUtils.js";
const isB2U = isBusinessVerticalB2U();
const CourseMenu = forwardRef((props, ref) => {
	const {
		items,
		footer,
		resetMenu,
		isMobile = false,
		className = "",
		onClose,
		pageSection,
		context,
	} = props;
	const [selectedItemIndex, setSelectedItemIndex] = useState(0);
	const [showMenu, setShowMenu] = useState(true);
	const [showActions, setShowActions] = useState(false);
	const isSafari = isSafariBrowser();

	useEffect(() => {
		if (!resetMenu) {
			setSelectedItemIndex(undefined);
		}
	}, [resetMenu]);

	const handleMenuItemClick = (menu, index) => {
		setSelectedItemIndex(index);
		if (isMobile) {
			setShowMenu(false);
			setShowActions(true);
		}
		// data event start
		addToDataLayer({
			event: "header_course_category_click",
			course_category: menu?.name,
		});
		// data event end
	};

	const handleActionItemClick = (course, clickEvent) => {
		setSelectedItemIndex(undefined);
		handleCTAClick(
			{
				event: "header_course_link_click",
				link: {
					type: "internal",
					link: course.link,
					target: "_self",
				},
			},
			{
				course_name: course.name,
				is_job_guarantee: course.isJobGuarantee,
				redirect_to: course.link,
			},
			{},
			clickEvent,
		);
	};

	const renderAllCourses = () => {
		return items.map(({ actions }, index) => {
			const { courses } = actions;
			return (
				<>
					<Courses
						className={selectedItemIndex === index ? "show" : ""}
						key={index}
						show={index !== selectedItemIndex}
						isSafari={isSafari}
					>
						<Text style={{ marginBottom: "32px" }}>{actions.description}</Text>
						{renderCourses(courses)}
					</Courses>
				</>
			);
		});
	};

	const renderCourses = (courses) => {
		return courses.map((course, cIndex) => {
			const { name, isJobGuarantee, link } = course;
			return (
				<>
					<CourseActionItem cIndex={cIndex} key={cIndex}>
						{isJobGuarantee ? (
							<Badge
								text="Job Guarantee"
								textColor="#008E67"
								bgColor="#C7FFE1"
							/>
						) : null}
						<Link
							href={link}
							arrowCta
							onClick={(e) => {
								onClose();
								handleActionItemClick(course, e);
							}}
						>
							<Text variant="paragraph_M_bold">{name}</Text>
						</Link>
					</CourseActionItem>
					<Separator />
				</>
			);
		});
	};

	return (
		<CourseMenuWrapper
			ref={ref}
			className={`${className} course-menu`}
			isMobile={isMobile}
		>
			<LeftSection show={showMenu}>
				<CourseMenuItemWrapper>
					{items.map((menu, index) => (
						<li>
							<CourseMenuItem
								onClick={() => handleMenuItemClick(menu, index)}
								className={
									!isMobile && index === selectedItemIndex ? "active" : ""
								}
								bgColor={menu?.bgColor}
								textColor={menu?.courseColor}
								key={"course_" + index}
								isMobile={isMobile}
								role="dialog"
								aria-expanded={index === selectedItemIndex}
								aria-controls="course-actions-menu"
							>
								<Icon iconName={menu?.icon} size="22px" />
								<Text variant="paragraph_L">{menu?.name}</Text>
							</CourseMenuItem>
						</li>
					))}
				</CourseMenuItemWrapper>
				{!isMobile ? (
					<>
						<Separator />
						<CourseMenuFooter>
							<Text variant="paragraph_S">{footer.text}</Text>
							<Link
								href={footer?.linkCta?.link?.link}
								target={footer?.linkCta?.link?.target}
								arrowCta
								onClick={(e) => {
									// This is where we have the career quiz typeform
									// We don't want to enable cmd/ctrl+click here, so e is not passed to handleCTAClick
									e.preventDefault();
									onClose();
									handleCTAClick(
										footer.linkCta,
										{
											page_section: pageSection,
											click_text: footer.linkCta?.linkText,
											redirect_to: footer.linkCta?.link?.link,
										},
										{ context },
									);
								}}
							>
								<Text variant="paragraph_M_bold">
									{footer.linkCta?.linkText}
								</Text>
							</Link>
							<Button
								buttonConfiguration={isB2U && footer.buttonCta}
								buttonText={footer.buttonCta?.buttonText}
								onClick={() => {
									onClose();
									handleCTAClick(footer.buttonCta, {
										page_section: pageSection,
										click_text: footer.buttonCta?.buttonText,
										redirect_to: footer.buttonCta?.link?.link,
									});
								}}
							/>
						</CourseMenuFooter>
					</>
				) : (
					<CourseMenuFooter>
						<Link
							href={footer?.linkCta?.link?.link}
							target={footer?.linkCta?.link?.target}
							className="footerLinkMobile"
							onClick={(e) =>
								handleCTAClick(
									footer.linkCta,
									{
										page_section: pageSection,
										click_text: footer.linkCta?.linkText,
										redirect_to: footer.linkCta?.link?.link,
									},
									{ context },
									{},
									e,
								)
							}
						>
							<Text
								variant="paragraph_M_bold"
								color={{
									base: "primary",
									color: "neutral-nightshade",
									shade: "800",
								}}
							>
								{footer.linkCta?.linkText}
							</Text>
						</Link>
						<Link
							href={footer?.buttonCta?.link?.link}
							target={footer?.buttonCta?.link?.target}
							onClick={(e) => {
								onClose();
								handleCTAClick(
									footer.buttonCta,
									{
										page_section: pageSection,
										click_text: footer.buttonCta?.buttonText,
										redirect_to: footer.buttonCta?.link?.link,
									},
									{ context },
									{},
									e,
								);
							}}
						>
							<Text
								variant="paragraph_M_bold"
								color={{
									base: "primary",
									color: "neutral-nightshade",
									shade: "800",
								}}
							>
								{footer.buttonCta?.buttonText}
							</Text>
						</Link>
					</CourseMenuFooter>
				)}
			</LeftSection>
			{selectedItemIndex >= 0 ? (
				<>
					{!isMobile ? (
						<AnimatedDiv selectedItemIndex={selectedItemIndex}>
							<Separator vertical />
							<RightSection>
								<CourseActions role="dialog" id="course-actions-menu">
									{renderAllCourses()}
								</CourseActions>
							</RightSection>
						</AnimatedDiv>
					) : (
						<>
							<CourseActionsMobile show={showActions}>
								<Link
									iconConfig={{ iconName: "leftArrow" }}
									onClick={() => {
										setShowActions(false);
										setShowMenu(true);
									}}
								>
									Back
								</Link>
								<CourseActionsHeading>
									<Icon iconName={items[selectedItemIndex]?.icon} size="22px" />
									<Text variant="paragraph_L">
										{items[selectedItemIndex]?.name}
									</Text>
								</CourseActionsHeading>
								<Text style={{ marginBottom: "32px" }}>
									{items[selectedItemIndex].actions.description}
								</Text>
								{items[selectedItemIndex].actions.courses.map(
									(course, index) => (
										<React.Fragment key={index}>
											<CourseActionItem cIndex={true}>
												{course?.isJobGuarantee ? (
													<Badge
														text="Job Guarantee"
														textColor="#008E67"
														bgColor="#C7FFE1"
													/>
												) : null}
												<Link
													onClick={(e) => handleActionItemClick(course, e)}
													href={course?.link}
												>
													<Text
														variant="paragraph_M_bold"
														color={{
															base: "primary",
															color: "neutral-nightshade",
															shade: "800",
														}}
													>
														{course?.name}
													</Text>
												</Link>
											</CourseActionItem>
											<Separator />
										</React.Fragment>
									),
								)}
								<CourseActionItem cIndex={true}>
									<Link
										href={footer?.linkCta?.link?.link}
										target={footer?.linkCta?.link?.target}
										onClick={(e) =>
											handleCTAClick(
												footer.linkCta,
												{
													page_section: pageSection,
													click_text: footer.linkCta?.linkText,
													redirect_to: footer.linkCta?.link?.link,
												},
												{},
												e,
											)
										}
									>
										<Text
											variant="paragraph_M_bold"
											color={{
												base: "primary",
												color: "neutral-nightshade",
												shade: "800",
											}}
										>
											{footer.linkCta?.linkText}
										</Text>
									</Link>
								</CourseActionItem>
								<Separator />
								<CourseActionItem cIndex={true}>
									<Link
										href={footer?.buttonCta?.link?.link}
										target={footer?.buttonCta?.link?.target}
										onClick={(e) =>
											handleCTAClick(
												footer.buttonCta,
												{
													page_section: pageSection,
													click_text: footer.buttonCta?.buttonText,
													redirect_to: footer.buttonCta?.link?.link,
												},
												{},
												e,
											)
										}
									>
										<Text
											variant="paragraph_M_bold"
											color={{
												base: "primary",
												color: "neutral-nightshade",
												shade: "800",
											}}
										>
											{footer.buttonCta.buttonText}
										</Text>
									</Link>
								</CourseActionItem>
							</CourseActionsMobile>
						</>
					)}
				</>
			) : (
				<></>
			)}
		</CourseMenuWrapper>
	);
});
export default CourseMenu;
