import React from "react";
import { Container } from "./styles";
import proptypes from "prop-types";

const Video = (props) => {
	const getVideoDetails = (url) => {
		//YOUTUBE MATCH
		const youtubeRegex =
			/^.*(youtu.be\/|v\/|embed\/|watch\?|youtube.com\/user\/[^#]*#([^\/]*?\/)*)\??v?=?([^#\&\?]*).*/;
		const youtubeMatch = url.match(youtubeRegex);
		if (youtubeMatch) {
			return {
				type: "youtube",
				videoId: youtubeMatch[3],
			};
		}
		//VIMEO MATCH
		const vimeoRegex =
			/(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
		const vimeoMatch = url.match(vimeoRegex);
		if (vimeoMatch) {
			return {
				type: "vimeo",
				videoId: vimeoMatch[4],
			};
		}
		return {
			type: "htmlVideo",
			url,
		};
	};

	const renderVideo = () => {
		const videoDetails = getVideoDetails(props.url);
		if (videoDetails.type === "youtube") {
			return (
				<iframe
					className="lazyload"
					data-src={`https://www.youtube.com/embed/${videoDetails.videoId}`}
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				/>
			);
		}
		if (videoDetails.type === "vimeo") {
			return (
				<iframe
					className="lazyload"
					data-src={`https://player.vimeo.com/video/${videoDetails.videoId}?portrait=0`}
					frameBorder="0"
					allow="autoplay; fullscreen"
					allowFullScreen
				/>
			);
		}
		//FALL BACK ON HTML5 VIDEO
		return <video src={videoDetails.url} controls />;
	};
	return <Container>{renderVideo()}</Container>;
};

Video.defaultProps = {
	url: "",
};
Video.propType = {
	url: proptypes.string,
};

export default Video;
