import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import {
	Text,
	Badge,
	Icon,
	Separator,
	Link,
} from "@website-builder/ui/shared/elements";
import {
	CourseLink,
	CourseCategory,
	MenuWrapper,
	CourseLinks,
	CourseInfo,
} from "./styles";
import { handleCTAClick } from "@website-builder/utilities/utils/DesignUtils.js";

const MegaCourseMenu = forwardRef((props, ref) => {
	const {
		column1,
		column2,
		column3,
		isMobile = false,
		context,
		pageSection,
		className = "",
	} = props;

	const courseInfo = [];

	const JGBadge = (
		<Badge
			text="Job Guarantee"
			textColor="var(--primary-forest-400)"
			bgColor="#C7FFE1"
		/>
	);

	const handleCourseLinkClick = (event, courseName, link, hasJG) => {
		handleCTAClick(
			{
				event: "header_course_link_click",
				link: {
					type: "internal",
					link: link?.link,
					target: "_self",
				},
			},
			{
				course_name: courseName,
				is_job_guarantee: hasJG,
				redirect_to: link?.link,
			},
			{},
			event,
		);
	};

	const courseLink = (idx, text, link, hasJG) => {
		return (
			<Link
				key={`course-link-${idx}`}
				href={link?.link}
				target={link?.target}
				onClick={(e) => {
					handleCourseLinkClick(e, text, link, hasJG);
				}}
			>
				<CourseLink>
					<Text>{text}</Text>
					{hasJG ? JGBadge : null}
				</CourseLink>
			</Link>
		);
	};

	const menuItems = (categories) => {
		return categories?.map((category, index) => {
			if (category.component === "megaCourseMenuInfo") {
				if (isMobile) {
					courseInfo.push(category);
					return <></>;
				}
				return courseMenuInfo(category);
			}
			return (
				<CourseCategory key={index}>
					<Icon iconName={category.icon?.[0]?.iconName} size={"24px"} />
					<CourseLinks>
						<Text variant="paragraph_L_bold">{category.title}</Text>
						{category.courseItems?.map((courseItem, index) =>
							courseLink(
								index,
								courseItem.courseName,
								courseItem.courseLink,
								courseItem.hasJG,
							),
						)}
					</CourseLinks>
				</CourseCategory>
			);
		});
	};

	const courseMenuInfo = (info) => (
		<CourseInfo>
			<Text variant="paragraph_L_bold">{info?.text}</Text>
			<Link
				href={info?.link?.[0]?.link?.link}
				target={info?.link?.[0]?.link?.target}
				linkText={info?.link?.[0]?.linkText}
				arrowCta
				onClick={(e) => {
					e.preventDefault();
					handleCTAClick(
						{ ...info?.link?.[0], event: info?.link?.[0]?.event },
						{
							page_section: pageSection,
							click_text: info?.link?.[0]?.linkText,
							redirect_to: info?.link?.[0]?.link?.link,
						},
						{ context },
					);
				}}
			/>
		</CourseInfo>
	);

	return (
		<MenuWrapper ref={ref} className={`${className} mega-course-menu`}>
			{!isMobile ? (
				<>
					<div className="mega-course-menu__col-1">{menuItems(column1)}</div>
					<Separator vertical className="course-menu-separator" />
					<div className="mega-course-menu__col-2">{menuItems(column2)}</div>
					{column3?.length > 0 ? (
						<>
							<Separator vertical className="course-menu-separator" />
							<div className="mega-course-menu__col-3">
								{menuItems(column3)}
							</div>
						</>
					) : null}
				</>
			) : (
				<div className="mega-course-menu__col-1">
					{menuItems(column1)}
					{menuItems(column2)}
					{column3?.length > 0 ? menuItems(column3) : null}
					{courseInfo.map((info, index) => courseMenuInfo(info))}
				</div>
			)}
		</MenuWrapper>
	);
});

MegaCourseMenu.propTypes = {
	column1: PropTypes.arrayOf(PropTypes.object),
	column2: PropTypes.arrayOf(PropTypes.object),
	column3: PropTypes.arrayOf(PropTypes.object),
	isMobile: PropTypes.bool,
};

MegaCourseMenu.defaultProps = {
	column1: [],
	column2: [],
	column3: [],
};
export default MegaCourseMenu;
