import React from "react";

import { lineGraphicCollection } from "./lineGraphicCollection";
import { Line } from "./styles";

const LineGraphic = ({
	line,
	stroke,
	opacity,
	thickness,
	size,
	top,
	right,
	bottom,
	left,
	x,
	y,
	rotate,
	flip = false,
	...restProps
}) => {
	if (!lineGraphicCollection.hasOwnProperty(line)) return <></>;
	return (
		<Line
			stroke={stroke}
			opacity={opacity}
			thickness={thickness}
			size={size}
			top={top}
			right={right}
			bottom={bottom}
			left={left}
			x={x}
			y={y}
			rotate={rotate}
			flip={flip}
			{...restProps}
			aria-hidden="true"
		>
			{lineGraphicCollection[line]}
		</Line>
	);
};

export default LineGraphic;
