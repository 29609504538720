import { STATIC_URLS } from "@website-builder/utilities/constants/config/config.js";
import { generateTimeStamp } from "@website-builder/utilities/utils/helpers.js";

export const getAllSpaces = () => {
	let headers = new Headers();
	headers.append("Content-Type", "application/json");
	headers.append("Authorization", process.env.GATSBY_STORYBLOK_MANAGMENT_TOKEN);

	let requestOptions = {
		method: "GET",
		headers: headers,
	};
	return new Promise(async (res, rej) => {
		try {
			const data = await fetch(
				`${STATIC_URLS.STORYBLOK_MANAGEMENT_API}/spaces/`,
				requestOptions,
			);
			const { spaces } = await data.json();
			if (spaces) {
				res(spaces);
			} else {
				rej("No storyblok space returned from API");
			}
		} catch (error) {
			console.error("Error while calling getAllSpaces", error);
			rej("No storyblok space returned from API");
		}
	});
};

export const getAPIs = (spaceID) => {
	let headers = new Headers();
	headers.append("Content-Type", "application/json");
	headers.append("Authorization", process.env.GATSBY_STORYBLOK_MANAGMENT_TOKEN);

	let requestOptions = {
		method: "GET",
		headers: headers,
	};

	let tokens = null;
	return new Promise(async (res, rej) => {
		try {
			const data = await fetch(
				`${STATIC_URLS.STORYBLOK_MANAGEMENT_API}/spaces/${spaceID}/api_keys`,
				requestOptions,
			);
			const { api_keys } = await data.json();
			tokens = api_keys.reduce((accumulator, { access, token }) => {
				return (accumulator = { ...accumulator, ...{ [access]: token } });
			}, {});
			if (tokens) {
				res(tokens);
			} else {
				rej("Error in getting token from API");
			}
		} catch (error) {
			rej("No storyblok space returned from API", error);
		}
	});
};

export const getStoriesByContentType = (
	contentType,
	token,
	pagination = 25,
) => {
	// pagination is 25 by default according to storyblok API docs
	const cv = generateTimeStamp();
	const queryParams = [
		`filter_query[component][in]=${contentType}`,
		`token=${token}`,
		`cv=${cv}`,
		`version=draft`,
		`per_page=${pagination}`,
	];
	return new Promise(async (res, rej) => {
		try {
			const result = await fetch(
				`${STATIC_URLS.STORYBLOCK_CMS_API}/cdn/stories?${queryParams.join(
					"&",
				)}`,
			);
			const { stories } = await result.json();
			if (!stories) rej("Invalid request");
			res(stories);
		} catch (error) {
			console.error(error);
			rej("Invalid request");
		}
	});
};

export const getStoryBySlug = (
	token,
	fullSlug,
	resolve_relations = ["postapp.layout", "course.layout", "template.layout"],
	version = "draft",
) => {
	const cv = generateTimeStamp();
	const queryParams = [
		`token=${token}`,
		`cv=${cv}`,
		`version=${version}`,
		`resolve_relations=${resolve_relations.join(",")}`,
	];

	return new Promise(async (res, rej) => {
		try {
			const result = await fetch(
				`${
					STATIC_URLS.STORYBLOCK_CMS_API
				}/cdn/stories/${fullSlug}?${queryParams.join("&")}`,
			);
			const { story } = await result.json();
			if (!story) rej("Invalid request");
			res(story);
		} catch (error) {
			console.error(error);
			rej("Invalid request");
		}
	});
};

export const getDataSources = (
	spaceId,
	dataSourceSlug,
	completeObject = false,
) => {
	let headers = new Headers();
	headers.append("Content-Type", "application/json");
	headers.append("Authorization", process.env.GATSBY_STORYBLOK_MANAGMENT_TOKEN);

	let requestOptions = {
		method: "GET",
		headers: headers,
	};
	return new Promise(async (res, rej) => {
		try {
			const dataSourceList = await fetch(
				`${STATIC_URLS.STORYBLOK_MANAGEMENT_API}/spaces/${spaceId}/datasources`,
				requestOptions,
			);
			let { datasources } = await dataSourceList.json();
			datasources =
				datasources && Array.isArray(datasources) && datasources.length
					? datasources
					: [];

			if (datasources) {
				const datasourceId = datasources.filter(
					({ slug }) => dataSourceSlug === slug,
				)[0]?.id;

				const data = await fetch(
					`${STATIC_URLS.STORYBLOK_MANAGEMENT_API}/spaces/${spaceId}/datasource_entries/?datasource_id=${datasourceId}&per_page=1000`,
					requestOptions,
				);
				const { datasource_entries } = await data.json();

				if (datasource_entries) {
					if (completeObject) {
						res(datasource_entries);
					}
					let datasourceObj = {};
					datasource_entries.map(({ name, value }) => {
						datasourceObj[name] = value;
					});
					res(datasourceObj);
				} else {
					rej(
						"No storyblok data sources returned from API, please create basic config data source",
					);
				}
			} else {
				rej(
					"No storyblok data sources returned from API, please create basic config data source",
				);
			}
		} catch (error) {
			console.error("Error while calling getDataSource", error);
			rej(
				"No storyblok data sources returned from API, please create basic config data source",
			);
		}
	});
};
