import {
	transformLinkWithLabel,
	richTextResolver,
	getLogoOrImageConfig,
} from "@website-builder/utilities-index";

export const transform = (header, globalVariableWithURL = [], stickyHeader) => {
	const {
		logoConfig = {},
		hasNavigations = false,
		navigations = [],
		hasFloatingHeader = false,
		floatingHeader = [],
		backgroundColor = "default",
	} = header;

	return {
		logoConfig: getLogoOrImageConfig(logoConfig),
		hasNavigations: hasNavigations.value,
		navigations: navigations.map(
			({ name = "", link = {}, dropdownLinks = [] }) => {
				return {
					name,
					link: transformLinkWithLabel(link),
					dropdownLinks: dropdownLinks.map(({ name = "", link = {} }) => {
						return {
							name,
							link: transformLinkWithLabel(link),
						};
					}),
				};
			},
		),
		hasFloatingHeader: hasFloatingHeader.value,
		stickyHeader: {
			...stickyHeader,
			backgroundColor: stickyHeader?.backgroundColor?.color,
			linkColor: stickyHeader?.linkColor?.color,
			buttonColor: stickyHeader?.buttonColor?.color,
			buttonTextColor: stickyHeader?.buttonTextColor?.color,
		},
		floatingHeader: floatingHeader.map(({ icon = "", content }) => {
			return {
				icon,
				content: richTextResolver(content, globalVariableWithURL),
			};
		}),
		backgroundColor,
	};
};
