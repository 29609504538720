import { TABLET_M_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import styled from "styled-components";

export const StyledFormContainer = styled.div`
	margin-top: 20px;
	background: ${({
		topBackgroundColor = "#ffffff",
		bottomBackgroundColor = "var(--theme-secondary)",
	}) =>
		`linear-gradient(180deg, ${topBackgroundColor}  0 calc(50% - 2.5rem), ${bottomBackgroundColor}  calc(50% - 2.5rem) 100%)`};
	form {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		max-width: ${({ maxWidth }) => maxWidth};
		& > div {
			width: 100%;
			padding: 5px 10px;
			box-sizing: border-box;
			&.half-field {
				width: calc(50% - 46px);
			}
			@media only screen and (max-width: ${TABLET_M_BP}px) {
				&.half-field {
					width: 100%;
				}
				padding: 2px 10px;
				width: 100%;
			}
		}
		@media only screen and (max-width: ${TABLET_M_BP}px) {
			padding: 0;
		}
	}

	.form-content {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
	}
	.hubspot-form-wrapper {
		margin: 46px 58px;
		form .label-text {
			text-transform: none;
		}
		${({ isTwoColumnSection }) =>
			isTwoColumnSection &&
			`
      margin: 24px 36px;
      padding: 36px 24px;
      & form > div > button {
          width: 100%;
      }
    `}
		@media only screen and (max-width: ${TABLET_M_BP}px) {
			margin: 24px 0px;
			padding: 36px 24px;
			& form > div > button {
				width: 100%;
			}
		}
	}

	.form-content-image {
		display: flex;
		justify-content: center;

		picture img {
			max-width: 100%;
		}
	}
`;
