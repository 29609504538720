import styled from "styled-components";

export const LoaderWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	min-height: 200px;

	.loader {
		display: inline-block;
		width: 80px;
		height: 80px;
	}
	.loader:after {
		content: " ";
		display: block;
		width: 56px;
		height: 56px;
		margin: 8px;
		border-radius: 50%;
		border: 6px solid ${({ darkBg }) => (!darkBg ? "#0E5642" : "#fff")};
		border-color: ${({ darkBg }) =>
			!darkBg
				? "#0E5642 transparent #0E5642 transparent"
				: "#fff transparent #fff transparent"};
		animation: loader 1.2s linear infinite;
	}
	@keyframes loader {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

export const PhoneInputWrapper = styled.div`
	.label-text {
		.required::after {
			content: "*";
			margin-left: 0.25rem;
			color: var(--secondary-red-300);
		}
	}
	.phone-field-container {
		display: flex;
		justify-content: space-between;

		.dial-code-input {
			width: 20%;
			& > select {
				height: 40px;
			}
		}
		.phone-input {
			width: ${({ includeDialCodes }) => (includeDialCodes ? "75%" : "100%")};
			& > .label-text {
				display: none !important;
			}

			input[type="number"]::-webkit-inner-spin-button,
			input[type="number"]::-webkit-outer-spin-button {
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				margin: 0;
			}
		}
	}
`;
