import { TABLET_M_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import { isBusinessVerticalB2U } from "@website-builder/utilities/utils/helpers.js";
import styled from "styled-components";
const isB2U = isBusinessVerticalB2U();
export const FormWrapper = styled.form`
	color: ${(props) =>
		props.darkBg
			? "var(--primary-neutral-white)"
			: "var(--primary-neutral-nightshade-800)"};

	.required::after {
		content: "*";
		margin-left: ${isB2U ? 0 : "0.25rem"};
		color: var(--secondary-red-300);
	}
	.boolean-input-text.required::after {
		content: "";
	}
	.required.boolean-input-text div > p:last-child::after {
		content: "*";
		margin-left: ${isB2U ? 0 : "0.25rem"};
		color: var(--secondary-red-300);
	}
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		margin-bottom: 1.5rem;
	}

	.label-text {
		display: block;
		margin-bottom: 0.5rem;
		color: ${(props) =>
			props.darkBg
				? "var(--primary-neutral-white)"
				: "var(--primary-neutral-nightshade-600)"};
	}

	.errorMessage {
		color: ${(props) =>
			props.darkBg ? "var(--secondary-red-100)" : "var(--secondary-red-300)"};
		margin: 0;
	}
`;

const textStyle = `
  font-family: var(--desktop-body-l-regular-ff);
  font-weight: var(--desktop-body-l-regular-fw);
  line-height: var(--desktop-body-l-regular-lh);
  font-size: var(--desktop-body-l-regular-fs);
  letter-spacing: var(--desktop-body-l-regular-ls);
  text-transform: var(--desktop-body-l-regular-tc);
  text-decoration: var(--desktop-body-l-regular-td);
  color: var(--primary-neutral-nightshade-800);
  text-align: start;

  @media only screen and (max-width: ${TABLET_M_BP}px) {
    font-family: var(--mobile-body-l-regular-ff);
    font-weight: var(--mobile-body-l-regular-fw);
    line-height: var(--mobile-body-l-regular-lh);
    font-size: var(--mobile-body-l-regular-fs);
    letter-spacing: var(--mobile-body-l-regular-ls);
    text-transform: var(--mobile-body-l-regular-tc);
    text-decoration: var(--mobile-body-l-regular-td);
  }
`;

const boxStyle = `
  color: var(--primary-neutral-nightshade-800);
  width: 100%;
  ${textStyle}

  background: var(--primary-neutral-white);
  border: 1px solid var(--secondary-gray-medium);
  border-radius: 4px;
`;

export const InputWrapper = styled.div`
	margin: 0 0 1rem 0;
`;

export const TextAreaWrapper = styled.div`
	margin-bottom: 1rem;

	.required::after {
		content: "*";
		margin-left: 0.25rem;
		color: var(--secondary-red-300);
	}
	textarea {
		color: var(--primary-neutral-nightshade-800);
		width: 100%;
		font-family: var(--desktop-body-l-regular-ff);
		font-weight: var(--desktop-body-l-regular-fw);
		line-height: var(--desktop-body-l-regular-lh);
		font-size: var(--desktop-body-l-regular-fs);
		letter-spacing: var(--desktop-body-l-regular-ls);
		text-transform: var(--desktop-body-l-regular-tc);
		text-decoration: var(--desktop-body-l-regular-td);
		color: var(--primary-neutral-nightshade-800);
		text-align: start;

		@media only screen and (max-width: ${TABLET_M_BP}px) {
			font-family: var(--mobile-body-l-regular-ff);
			font-weight: var(--mobile-body-l-regular-fw);
			line-height: var(--mobile-body-l-regular-lh);
			font-size: var(--mobile-body-l-regular-fs);
			letter-spacing: var(--mobile-body-l-regular-ls);
			text-transform: var(--mobile-body-l-regular-tc);
			text-decoration: var(--mobile-body-l-regular-td);
		}

		background: var(--primary-neutral-white);
		border: 1px solid var(--secondary-gray-medium);
		border-radius: 4px;
		padding: 0.5rem;

		::placeholder {
			color: var(--secondary-gray-dark);
		}

		:active,
		:focus {
			outline: none;
			border-color: var(--primary-forest-400);
		}
	}

	.submit-button {
		text-align: center;
	}
`;

export const MainWrapper = styled.div`
	margin: 0 0 1rem 0;
	.help-text {
		margin-bottom: 0.5rem;
	}

	input {
		${boxStyle}
		padding: 0.5rem;

		::placeholder {
			color: var(--secondary-gray-dark);
		}

		:active,
		:focus {
			outline: none;
			border-color: var(--primary-forest-400);
		}
	}

	input.error {
		border-color: var(--secondary-red-200);
	}
`;

export const ErrorSection = styled.div`
	display: flex;
	align-items: center;
	gap: 0.25rem;
	margin-top: 8px;

	& > figure > svg > path:not(:last-child) {
		stroke: var(--secondary-red-200);
	}
	& > figure > svg > path:last-child {
		fill: var(--secondary-red-200);
	}
`;

export const SelectWrapper = styled.div`
	margin: 0;
	padding: 0;
	position: relative;
`;
export const BooleanItemWrapper = styled.div`
	margin: 0;
	label {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		p {
			display: inline-block;
			margin-bottom: 0.3125rem;
		}
	}
	input {
		width: auto;
		margin: 0.3125rem;
		cursor: pointer;
	}
	input:focus {
		outline: 1px solid var(--primary-forest-400);
	}
	.boolean-input-text {
		display: flex;
		flex-direction: row;
		align-items: start;
		& input {
			margin: 5px 4px 0;
		}
		& p {
			margin: 0 1px 0;
		}
	}
`;
export const OptionsList = styled.div`
	${(props) => (props.expanded ? "z-index: 1;" : "display: none;")}
	${boxStyle}
  border: 1px solid var(--primary-forest-400);
	position: absolute;
	top: 0;
	width: 100%;
	max-height: 300px;
	overflow-y: scroll;
	padding-top: 0.3125rem;

	.btn.gray-text {
		color: var(--secondary-gray-dark);
	}

	.btn {
		${textStyle}
		padding: 0.5rem;
		margin: 0;
		pointer-events: auto;
		cursor: pointer;
		width: 100%;
		background: transparent;
		border: none;
		height: fit-content;

		&.dark-on-hover:hover {
			font-weight: var(--fw-haffer-xh-2);
		}
	}

	button.btn:hover {
		background-color: var(--secondary-gray-x-light);
	}

	.selected {
		font-weight: var(--fw-haffer-xh-2);
	}
`;

export const Caret = styled.i`
	position: absolute;
	right: 16px;
	top: 22.5px;
	transform: ${(props) =>
		props.expanded ? "scaleY(-1) translateY(50%)" : "translateY(-50%)"};
	width: 0;
	height: 0;
	display: inline-block;
	border-left: 0.5rem solid transparent;
	border-right: 0.5rem solid transparent;
	border-top: 0.5rem solid var(--primary-neutral-nightshade-800);
	border-bottom: none;
	transition: transform 200ms linear;
`;

export const DownwardsArrow = styled.i`
	position: absolute;
	top: 50%;
	right: 11px;
	border: solid black;
	border-width: 0 1px 1px 0;
	display: inline-block;
	padding: 2.5px;
	transform: rotate(45deg) translateY(-100%);
	pointer-events: none;
`;

export const SelectedItemWrapper = styled.div`
	${boxStyle}
	height: 45px;
	${(props) =>
		props.error ? "border: 1px solid var(--secondary-red-200);" : null}
	.btn {
		${textStyle}
		padding: 0.5rem;
		margin: 0;
		pointer-events: auto;
		cursor: pointer;
		width: 100%;
		background: transparent;
		border: none;
		height: 45px;
	}
	.style-selected-item {
		padding-right: 30px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	.btn:focus {
		outline: none;
	}
	&:has(.btn:focus) {
		border: 1px solid var(--primary-forest-400);
	}
	.placeholder {
		color: var(--secondary-gray-dark);
	}
`;

export const SelectTagWrapper = styled.select`
	${boxStyle}
	height: 45px;
	&.placeholder-text {
		color: #bfbfbf;
		&:focus {
			color: #33475b;
		}
	}
	${(props) =>
		props.error ? "border: 1px solid var(--secondary-red-200);" : null}
	.btn {
		${textStyle}
		padding: 0.5rem;
		margin: 0;
		pointer-events: auto;
		cursor: pointer;
		width: 100%;
		background: transparent;
		border: none;
		height: 45px;
	}
	.style-selected-item {
		padding-right: 30px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	.btn:focus {
		outline: none;
	}
	&:has(.btn:focus) {
		border: 1px solid var(--primary-forest-400);
	}
	.placeholder {
		color: var(--secondary-gray-dark);
	}
`;

export const OptionLabel = styled.div`
	${textStyle}
	padding: 0.5rem;
	pointer-events: auto;
	margin: 0;
	width: 100%;
	background: transparent;
	border: none;
	height: 45px;
	color: var(--secondary-gray-dark);
`;

export const IconWrapper = styled.div`
	position: relative;
	.info-icon {
		display: flex;
		flex-direction: row;
		width: 17px;
		z-index: 2;
	}
	.info-help-text {
		display: none;
		position: absolute;
		background-color: var(--primary-neutral-white);
		border-radius: 11px;
		top: 0;
		min-width: 280px;
		max-width: 380px;
		max-height: 320px;
		padding: 10px 10px 2px 10px;
		transform: translateX(-100%) translateY(-100%);
		z-index: 1;
		left: 150%;
		white-space: pre-line;
		@media only screen and (max-width: ${TABLET_M_BP}px) {
			min-width: 280px;
			max-width: min(300px, 90vw);
			max-height: 500px;
			left: 250%;
			padding: 8px 8px 0 8px;
		}
	}
	.info-icon:hover ~ .info-help-text,
	.info-icon:focus ~ .info-help-text {
		display: block;
	}
`;

export const LabelWithHelpText = styled.div`
	display: flex;
	flex-direction: row;
`;
