import styled from "styled-components";
import { TABLET_M_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";

export const ColWrapper = styled.div`
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		grid-column: ${(props) =>
			props.mdStart
				? `${props.mdStart} / ${props.mdSpan}`
				: `span ${props.mdSpan}`};
		${(props) => props.mdRow && `grid-row: ${props.mdRow};`}
		${({ order }) => order && `order: ${order};`} // For TwoColumnSection
	}
	display: block;
	${(props) => props.row && `grid-row: ${props.row};`}
	${(props) => props.vAlign && `align-self: ${props.vAlign};`}
  ${(props) => props.hAlign && `justify-self: ${props.hAlign};`}

  grid-column: ${(props) =>
		props.start ? `${props.start} / ${props.span}` : `span ${props.span}`};
`;

export const GridWrapper = styled.div`
	display: grid;
	gap: 24px;
	margin: 0
		${(props) =>
			`${
				props.hMargin?.[0] || props.hMargin?.[0] === 0 ? props.hMargin?.[0] : 96
			}px`};
	align-items: ${(props) => `${props.vAlign}`};
	grid-template-columns: repeat(12, minmax(0, 1fr));
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		gap: 16px;
		grid-template-columns: repeat(6, minmax(0, 1fr));
		margin: 0
			${(props) =>
				`${
					props.hMargin?.[1] || props.hMargin?.[1] === 0
						? props.hMargin?.[1]
						: 24
				}px`};
	}
`;
