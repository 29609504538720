export const checkPadding = (Component) => {
	if (
		Component === "Accordion" ||
		Component === "UpcomingPrograms" ||
		Component === "QuickLinksPanel" ||
		Component === "BlogCategories" ||
		Component === "Tuition"
	)
		return false;
	else return true;
};

// This function is used to decide if there should be additional margin after a component
export const isAddMargin = (Component, section) => {
	const leftSectionInclusions = ["ApplicationForm", "LeadForm"]; // When forms are in the left section, no margin needed
	const rightSectionExclusions = ["CTAButton", "FormWrapper"]; // When these components are in right section, no margin needed

	const isLeftSectionForm =
		leftSectionInclusions.includes(Component) && section === "left";
	const isRightSectionNonButtonOrWrapper =
		!rightSectionExclusions.includes(Component) && section === "right";

	return isLeftSectionForm || isRightSectionNonButtonOrWrapper;
};

export const setCustomPadding = (component, section) => {
	let padding;
	if (section === "right") {
		switch (component) {
			case "CTAButton":
				padding = "18px";
				break;
			default:
				padding = "35px 25px 25px 25px";
		}
	}
	if (section === "body") {
		switch (component) {
			case "ApplicationFormWR":
			case "PortfolioShowcase":
				padding = "0px";
				break;
			default:
				padding = "18px 0px";
		}
	}
	return padding;
};
